<template>
  <div class="exerciseInstruction">
    <v-card class="mx-auto" dark>
      <v-row align="center" justify="center">
        <v-col cols="12" md="6">
          <h2 class="mb-3 d-inline">
            <span class="avidocolor--text">{{ exercise_name }}</span>
          </h2>
        </v-col>
      </v-row>

      <!-- <Media :kind="'video'" :controls="true"  preload="none" playsinline disablePictureInPicture controlsList="noplaybackrate nodownload"
          :src="exer_videourl" :poster="image_large" :style="{ 'width': '100%', 'min-width': '100%', 'max-width': ' 100%' }">
        </Media>  contain /cover  with height-->

      <!-- <Media :kind="'video'" :controls="true"  preload="none" playsinline disablePictureInPicture controlsList="noplaybackrate nodownload"
          :src="exer_videourl" :poster="image_large" :style="{ 'width': '100%', 'min-width': '100%', 'max-width': ' 100%', 'height' : '350px' }">
        </Media> -->

      <v-img :src="image_large" v-if="playVideo == 0" contain
        :height="$vuetify.breakpoint.xs ? '100%' : ($vuetify.breakpoint.sm ? '100%' : '300px')">
        <v-btn absolute bottom left icon color="avidocolor" @click="playVideo = 1">
          <v-icon size="40">mdi-play-circle-outline</v-icon>
        </v-btn>
      </v-img>
      <video v-else width="100%" :height="$vuetify.breakpoint.xs ? '100%' : ($vuetify.breakpoint.sm ? '100%' : '300px')"
        autoplay controls="false" poster="noposter" :src="exer_videourl" preload="none" disablePictureInPicture
        controlsList="noplaybackrate nodownload">
      </video>


      <v-card-title class="py-0">
        Target Muscles
      </v-card-title>

      <v-card-text class="text-left align-self-start">
        <v-chip color="black avidocolor--text">
          <strong>Primary: </strong>&nbsp;
          <span class="pl-2 white--text">{{ primary_muscles }}</span>
        </v-chip> &nbsp;
        <v-chip color="black avidocolor--text">
          <strong>Assisting: </strong>&nbsp;
          <span class="pl-2 white--text">{{ secondary_muscles }}</span>
        </v-chip>
      </v-card-text>
    </v-card>


    <v-card class="mt-2 mx-auto" dark>
      <v-tabs v-model="tab" centered dark background-color="black" grow>
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#notes" class="avidocolor--text">
          Notes
        </v-tab>

        <v-tab href="#history" class="avidocolor--text">
          History
        </v-tab>

        <v-tab href="#instructions" class="avidocolor--text">
          Instructions
        </v-tab>
        <v-tabs-slider color="avidocolor"></v-tabs-slider>
      </v-tabs>

      <v-tabs-items v-model="tab">

        <v-tab-item value="notes">
          <v-list class="pa-0"  v-if="!hasPersonalNote">
            <v-list-item>
              <v-list-item-content class="pl-2 text-left">
                <v-list-item-title class="wrap-text">Personal Note</v-list-item-title>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon color="avidocolor" @click=editPersonalNote(null)
                  class="float-right">mdi-pencil-box-outline</v-icon>
              </v-list-item-icon>
            </v-list-item>

            <v-divider width="98%" class="avidocolor mx-auto" v-if="user_exercise_notes.length>0"></v-divider>
                
          </v-list>

          <v-list v-if="user_exercise_notes.length > 0">
            <template v-for="(model, exindex) in user_exercise_notes">

              <v-list-item :key="(model.assignedBy + '_' + exindex)">

                <!--  <v-list-item-avatar tile size=50>
                    <v-img class="rounded" :alt="model.assignedBy" :src="(model.exercise_image_thumbnail!=null)?model.exercise_image_thumbnail:'/images/logo.jpg'" @error="model.exercise_image_thumbnail='/images/logo.jpg'"></v-img>
                  </v-list-item-avatar> -->

                <v-list-item-content class="pl-2 text-left">
                  <v-list-item-title class="wrap-text">
                    {{ model.assignedBy }} <span class="float-right" v-if="!model.is_personal_note"> {{ model.updated_by }} </span>
                  </v-list-item-title>
                  <v-list-item-subtitle class="pt-2 wrap-text">
                    <div class="text-left align-self-start font-weight-light subtitle-1"
                      v-for="(mtext, mindex) in model.notes.split('\n')" :key="mindex">
                      {{ mtext }}
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon v-if="model.is_personal_note">
                  <v-icon color="avidocolor" @click=editPersonalNote(model)
                    class="float-right">mdi-pencil-box-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
              
              <v-divider width="98%" class="avidocolor mx-auto" v-if="exindex < user_exercise_notes.length - 1"
                :key="exindex"></v-divider>
                
            </template>
          </v-list>

        </v-tab-item>

        <v-tab-item value="history">
          <v-card outlined>
            <v-card-title class="py-0">

              {{ zero_weight_label != 'MAX' ? 'Max Reps' : (user_1RM > 0 ? 'One Rep Max' : 'Estimated 1RM') }}

            </v-card-title>
            <v-card-text>
              <div class="text-left align-self-start subtitle-1">{{ zero_weight_label == 'body' ? result_1RM :
              (user_1RM > 0 ?
                user_1RM : result_1RM) }} {{ zero_weight_label == 'MAX' ? 'lbs (estimated)' : null }}</div>
            </v-card-text>

            <template v-if="Object.keys(reps_records).length > 0">
              <v-card-title class="py-0">
                Rep Records:
              </v-card-title>
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <th class="text-center" :style="{ 'border-bottom': `thin solid ${darktextcolor}` }">Reps</th>
                      <th class="text-center" :style="{ 'border-bottom': `thin solid ${darktextcolor}` }">Weight</th>
                      <th class="text-center" :style="{ 'border-bottom': `thin solid ${darktextcolor}` }">Date</th>
                    </tr>
                    <tr v-for="(repsweight, index) in reps_records" :key="index">
                      <td>{{ repsweight.reps }}</td>
                      <td>{{ repsweight.weight }}</td>
                      <td>{{ repsweight.date }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>


            <template v-if="user_exercise_history.length > 0">
              <v-card-title class="py-0">
                Recent Sets:
              </v-card-title>
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <template v-for="(exercise_history) in user_exercise_history">
                      <tr v-for="(item, index) in exercise_history.sets" :key="item.completion_date">
                        <td :style="(index == 0) ? { 'border-top': `thin solid ${darktextcolor}` } : null">
                          {{ (item.reps != null) ? item.reps + ' ' + exercise_history.reps_type : 0 }}</td>
                        <td :style="(index == 0) ? { 'border-top': `thin solid ${darktextcolor}` } : null">
                          {{ (item.weight != null) ? item.weight + ' ' + exercise_history.weight_type : 0 }}</td>
                        <td :style="(index == 0) ? { 'border-top': `thin solid ${darktextcolor}` } : null">
                          <span v-if="index == 0">{{ exercise_history.completion_date }} </span>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-card>
        </v-tab-item>

        <v-tab-item value="instructions">

          <v-card outlined>
            <template v-if="position_set_up != null">
              <v-card-title class="py-0">
                Position:
              </v-card-title>
              <v-card-text>
                <div class="text-left align-self-start subtitle-1"
                  v-for="(ptext, pindex) in position_set_up.split('\n')" :key="pindex">
                  {{ ptext }}
                </div>
              </v-card-text>
            </template>

            <template v-if="movement != null">
              <v-card-title class="py-0">
                Movement:
              </v-card-title>
              <v-card-text>
                <div class="text-left align-self-start subtitle-1" v-for="(mtext, mindex) in movement.split('\n')"
                  :key="mindex">
                  {{ mtext }}
                </div>
              </v-card-text>
            </template>

            <template v-if="cue_points != null">
              <v-card-title class="py-0">
                Coaching Cues:
              </v-card-title>
              <v-card-text>
                <div class="text-left align-self-start subtitle-1" v-for="(ctext, cindex) in cue_points.split('\n')"
                  :key="cindex">
                  {{ ctext }}
                </div>
              </v-card-text>
            </template>

          </v-card>

        </v-tab-item>
      </v-tabs-items>

    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
// import Media from '@dongido/vue-viaudio'

export default {
  name: "ExerciseInstruction",
  data: () => ({
    tab: '',
    darktextcolor: null,
    playVideo: 0,
  }),
  components: {
    // 'Media': Media,
  },
  computed: {
    ...mapFields('exerciseInstruction', {
      exercise_instruction_id: 'exercise_instruction_id',
      exercise_name: 'exercise_name',
      image_thumb: 'image_thumb',
      image_large: 'image_large',
      exer_videourl: 'exer_videourl',
      primary_muscles: 'primary_muscles',
      secondary_muscles: 'secondary_muscles',
      position_set_up: 'position_set_up',
      movement: 'movement',
      cue_points: 'cue_points',
      user_1RM: 'user_1RM',
      result_1RM: 'result_1RM',
      zero_weight_label: 'zero_weight_label',
      user_exercise_notes: 'user_exercise_notes',
      user_exercise_history: 'user_exercise_history',
      reps_records: 'reps_records',
      add_fav_exercise_id: 'add_fav_exercise_id',
      remove_fav_exercise_id: 'remove_fav_exercise_id',
    }),
    ...mapFields('workoutCalendar', {
      is_workout_editable: 'is_workout_editable',
    }),
    ...mapFields('exerciseNotes', {
      updateNote: 'updateNote',
      currentNoteExercise: 'currentNoteExercise',
    }),
    hasPersonalNote() {
      return this.user_exercise_notes.some(note => note.is_personal_note);
    }
  },
  methods: {
    ...mapActions("exerciseInstruction", {
      getModel: "getModel",
      favUnfavExercise: "favUnfavExercise",
    }),
    editPersonalNote(nmodel) {

      let nnote_id = 0
      let nnotes = null
      if (nmodel != null) {
        nnote_id = nmodel.note_id
        nnotes = nmodel.notes
      }
      let noteDetails = {
        id: nnote_id,
        exercise_id: this.exercise_instruction_id,
        notes: nnotes,
        is_personal_note: true,
        assigned_userids: [],
        assigned_teamids: []
      }
      /* this.currentNoteExercise = {
        exercise_id: this.exercise_instruction_id,
        exercise_name: this.exercise_name,
        exercise_image_thumbnail: this.image_thumb,
        exercise_large_image: this.image_large,
        exercise_video_url: this.exer_videourl,
        note_details: [noteDetails]
      } */

      this.updateNote = noteDetails
      this.$router.push({ path: 'edit-note?pn=1' });
    }
  },
  created() {
    this.darktextcolor = this.$vuetify.theme.themes.dark.avidocolor
    this.getModel();
  },
  destroyed() {
    if (this.add_fav_exercise_id > 0 || this.remove_fav_exercise_id > 0) {
      this.favUnfavExercise();
    }
  },
}
</script>

<style scoped>
:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}
</style>