<template>
  <div class="AddWorkout">

      <v-tabs  v-model="addworkout_tab" background-color="black avidocolor--text" grow>
        <v-tab href="#LIBRARY">
          LIBRARY
        </v-tab>
        <v-tab href="#BLANK" >
          BLANK
        </v-tab>
      </v-tabs>

  
      <v-form
        ref="addWorkoutForm"
        v-model="valid"
        lazy-validation
        @submit.prevent="onSubmit"
        method="post"
      >
          <v-tabs-items v-model="addworkout_tab">
            <v-card dark>
              <v-tab-item value="LIBRARY" v-if="addworkout_tab=='LIBRARY'">
                  <!--  <v-autocomplete
                      v-model="assign_library_workout_id"
                      name="assign_library_workout_id"
                      :items="user_LWorkouts_list"
                      class="pa-md-4"
                      style="padding-bottom: 0!important;"
                      background-color="transparent"
                      label="Search Library Workout..."
                      prepend-icon="mdi-magnify"
                      color="avidocolor"
                      item-text="name"
                      item-value="id"
                      filled
                      return-object
                    >
                      <template v-slot:item="data">
                        <v-list-item-avatar tile size=50>
                          <v-img class="rounded"
                            :src="
                              data.item.image != null
                                ? data.item.image
                                : '/images/logo.jpg'
                            "
                            @error="data.item.image = '/images/logo.jpg'"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete> -->

                <v-text-field v-model="search_keyword" placeholder="Search..." required clearable  name="searchInput"
                    color="avidocolor" prepend-icon="mdi-magnify" class="px-2" />

                <v-list v-if="filteredList.length > 0">
                  <v-list-item-group :key="itemGroup_filter" v-model="assign_library_workout_id"
                    color="avidocolor">
                    <template v-for="(model, mindex) in filteredList">
                      <v-list-item :key="(model.id + '_' + mindex)" :value="model" :ripple="false"
                        active-class="avidocolor--text text--accent-4">

                        <v-list-item-avatar tile size=50>
                          <v-img class="rounded" :alt="model.name" :src="(model.image != null) ? model.image : '/images/logo.jpg'"
                            @error="model.image = '/images/logo.jpg'"></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content class="text-left">
                          <v-list-item-title class="align-center" v-text="model.name"></v-list-item-title>
                        </v-list-item-content>

                      </v-list-item>
                      <v-divider v-if="mindex < filteredList.length - 1" :key="mindex"></v-divider>
                    </template>
                  </v-list-item-group>
                </v-list>

              </v-tab-item>

              <v-tab-item value="BLANK"  v-if="addworkout_tab=='BLANK'">
                <v-text-field
                  v-model="personal_workout_name"
                  label="Workout Name"
                  class="pa-md-4"
                  persistent-hint
                  required
                  color="avidocolor"
                  :rules="[(v) => !!v || 'Workout Name is required',(v) => !!v && v.length <= 50 || 'Max 50 characters allow.']"
                ></v-text-field>
              </v-tab-item>
            </v-card>
          </v-tabs-items>
        
          <div class="clear"></div>
          <v-footer color="black" fixed padless>
            <v-row justify="center" no-gutters>
              <v-btn type="submit" class="my-2 avidocolor--text" text>
                <v-icon>mdi-content-save</v-icon>save 
              </v-btn>
            </v-row>
          </v-footer>    
     </v-form>
    


  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "AddWorkout", 
  data() {
    return {
      valid: false,
      addworkout_tab: 'LIBRARY',
      search_keyword: null,
      itemGroup_filter: 0,
      items: [],
    };
  },
  computed: {
    ...mapFields("workoutCalendar", {
      user_LWorkouts_list: "user_LWorkouts_list",
      personal_workout_name: "personal_workout_name",
      assign_library_workout_id: "assign_library_workout_id",
    }),
    filteredList() { // for all items except 
      if (this.search_keyword != null) {
        return this.items.filter(item => {
          return item.name.toUpperCase().includes(this.search_keyword.toUpperCase())
        })
      } else {
        return this.items
      }
    },
  },
  
  methods: {
    ...mapActions("workoutCalendar", {
      savePersonalWorkout: "savePersonalWorkout",
      addLibraryWorkoutToCalendar: "addLibraryWorkoutToCalendar",
    }),
    onSubmit: function () {

      if(this.addworkout_tab=='LIBRARY'){
        if(this.assign_library_workout_id==0){
          this.$store.dispatch(
            "setNotification",
            {
              display: true,
              text: this.app_error_mesg,
              alertClass: "error",
            },
            { root: true }
          );
        }else{
          this.addLibraryWorkoutToCalendar();
        }
      }
      
      if(this.addworkout_tab=='BLANK'){
        if(this.personal_workout_name!=null){
          this.savePersonalWorkout()
        } else {
          this.$store.dispatch(
            "setNotification",
            {
              display: true,
              text: this.app_error_mesg,
              alertClass: "error",
            },
            { root: true }
          );
        }
      }

    },
  },
  created() {
      this.items=this.user_LWorkouts_list
  },
};
</script>
