<template>
  <div class="ViewLeaderBoard">

    <h2 class="mb-3 d-inline">
      <span class="avidocolor--text">{{ viewleaderBoard.team_name }}</span>
    </h2>
    
    <v-row class="text-h6 font-weight-regular" align="center" v-if="viewleaderBoard.lb_result.length > 0">
      <v-col cols="9" class="text-left">
        {{ viewleaderBoard.exercise_name }}
      </v-col>      
      <v-col cols="3" :class="{ 'text-right': $vuetify.breakpoint.smAndUp }">
        {{ (viewleaderBoard.result_type=='MAXREPS') ? 'MAX REPS' : 'MAX Weight' }}
      </v-col>
    </v-row>

    <template v-if="viewleaderBoard.lb_result.length > 0">
      <template v-for="(model, resultlbindex) in viewleaderBoard.lb_result">
        <v-card class="rounded-lg mb-1" :key="resultlbindex">
          <v-list-item :key="'rlblist' + resultlbindex">

            <v-list-item-action class="ma-0">
              {{ model.rank }}
            </v-list-item-action>

            <v-list-item-avatar size=50>
              <v-img :alt="model.user_name" :src="(model.profile_url!=null)? model.profile_url: 'images/logo.jpg'" @error="model.profile_url='images/logo.jpg'"></v-img>
            </v-list-item-avatar>

            <v-list-item-content class="pl-2 text-left">
              <v-list-item-title v-html="model.user_name" class="wrap-text"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action class="ma-0">
              <v-list-item-action-text>
                <div class="text-h5 white--text">{{ (model.result_amount>0) ? model.result_amount : '-' }}</div>
                {{ model.completion_date }}
              </v-list-item-action-text>

            </v-list-item-action>

          </v-list-item>
        </v-card>
      </template>
    </template>


  </div>
</template>



<script>
// import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
export default {
  name: "ViewLeaderBoard",
  computed: {
    ...mapFields('teamProfile', {
      viewleaderBoard: 'viewleaderBoard',
    })
  },
};
</script>