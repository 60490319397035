<template>
  <div class="profilesettings">

      <v-form
        ref="profilesettings"
        v-model="valid"
        lazy-validation
        @submit.prevent="onSubmit"
        method="post"
      >
       
    <v-card class="pa-8">
      
      <v-row>
        <v-col>
          <v-file-input
              required
              @change="onFileChange"
              v-model="userModel.profile_pic"
              id="image-upload"
              class="d-none"
          ></v-file-input>
          <v-avatar size="200"  @click="openUpload" :color="(userModel.imagePreviewURL!=null) ? null : 'avidocolor'">
            <v-img v-if="userModel.imagePreviewURL!=null" :alt="userModel.first_name" :src="(userModel.imagePreviewURL!=null) ? userModel.imagePreviewURL: null" @error="userModel.imagePreviewURL='/images/logo.jpg'"></v-img>
            <v-icon v-else color="black" x-large :class="(userModel.imagePreviewURL!=null) ? 'd-none' : null">
              mdi-camera
            </v-icon>
          </v-avatar>
          <br/>
          <br/>
          <v-btn
            @click.stop="userModel.imagePreviewURL!=null"
            text 
            class="avidocolor--text"
          >
            Remove Profile
          </v-btn>   
        </v-col>
      </v-row> 


      <v-textarea
        counter
        label="Bio / Goals"
        v-model="userModel.bio"
        class="pa-md-4"
        color="avidocolor"
      ></v-textarea>

     <!--  <v-row class="pl-md-4">
        <v-col align="left">
            Profile Privacy Setting :
            <v-btn-toggle
              v-model="userModel.privacy_setting"
              tile
              color="avidocolor"
              group
              mandatory
            >
              <v-btn v-if="userModel.user_age>=18" value="PUBLIC">
                PUBLIC
              </v-btn>

              <v-btn value="PRIVATE">
                PRIVATE
              </v-btn>

              <v-btn value="TEAMS">
                TEAMS
              </v-btn>
            </v-btn-toggle>
        </v-col>
      </v-row> -->  
      
      <v-autocomplete
        v-model="userModel.current_displayskin_id"
        name="userModel.current_displayskin_id"
        :items="userModel.display_skin_options"
        class="pa-md-4"
        label="Select Display Theme"
        color="avidocolor"
        item-text="name"
        item-value="id"
      >
        <template v-slot:item="data">
          <v-list-item-avatar tile size=50>
            <v-img class="rounded"
              :src="data.item.logo_image"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name" class="text-left"></v-list-item-title>
          </v-list-item-content>
           <v-list-item-avatar tile>
              <v-btn :color="data.item.dark_background_color">
                <v-icon :color="data.item.solid_text_color">mdi-format-color-fill</v-icon>
              </v-btn>
           </v-list-item-avatar>
        </template>
      </v-autocomplete>
<!-- 
 <v-dialog
    ref="dialog"
    v-model="modal2"
    :return-value.sync="time"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        label="Picker in dialog"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>

    <v-time-picker
      v-if="modal2"
      v-model="time"
      format="mm:ss"
      full-width
      use-seconds
      no-title
      active-picker="MINUTE,SECOND"
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="modal2 = false"
      >
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.dialog.save(time)"
      >
        OK
      </v-btn>
    </v-time-picker>
  </v-dialog> -->

        <v-footer color="black" fixed padless>
          <v-row justify="center" no-gutters>
            <v-btn type="submit"  class="my-2 avidocolor--text" text>
              <v-icon>mdi-content-save</v-icon>Save
            </v-btn>
          </v-row>
        </v-footer>
  
      </v-card>

      <div class="clear"></div>
      </v-form>

  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "Profilesettings",
  data: () => ({
    valid: true,
  //  imagePreviewURL: null,
  }),
  computed:{
    ...mapFields('accountSettings',{
      userModel:'userModel',
    })
  },
  methods: {
    ...mapActions('accountSettings',{
      getProfileModel:'getProfileModel',
      profileSettingSave:'profileSettingSave',
    }),
    openUpload () {
      document.getElementById('image-upload').click()
    },
    onFileChange(payload) {
      //const file = payload.target.files[0]; // use it in case of normal HTML input
      const file = payload; // in case vuetify file input
      if (file) {
        this.userModel.imagePreviewURL = URL.createObjectURL(file);
        URL.revokeObjectURL(file); // free memory
        // console.log(payload)
      } else {
        this.userModel.imagePreviewURL =  null
      }
    },
    onSubmit: function () {
      if (this.$refs.profilesettings.validate()) {
        let userModel = this.userModel;
        this.profileSettingSave(userModel);
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
  },
  created() {
    this.getProfileModel();
  },
  beforeDestroy(){
    this.$vuetify.theme.themes.dark.avidocolor = (typeof(localStorage.getItem("darkTextColor")) != "undefined" && localStorage.getItem("darkTextColor") !== null) ? localStorage.getItem("darkTextColor") : "#52F60C"
    this.$vuetify.theme.themes.dark.avidosolidtextcolor = (typeof(localStorage.getItem("solidTextColor")) != "undefined" && localStorage.getItem("solidTextColor") !== null) ? localStorage.getItem("solidTextColor") : "#000000" 
  }
};
</script>