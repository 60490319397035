<template>
  <div class="trainingpreferences">

      <v-form
        ref="trainingpreferencesform"
        v-model="valid"
        lazy-validation
        @submit.prevent="onSubmit"
        method="post"
      >
      <v-card flat class="pa-8">
        <v-select
          v-model="current_calendar"
          :items="current_calendar_option"
          item-color="avidocolor"
          label="Program Type"
          color="avidocolor"
          class="pa-md-4"
          :rules="[(v) => !!v || 'Program Type is required']"
        ></v-select> 
        
        <v-select
          v-if="current_calendar=='TEAM'"
          v-model="current_team_id"
          :items="athlete_team"
          item-text="name"
          item-value="id"
          item-color="avidocolor"
          label="Select Team"
          color="avidocolor"
          class="pa-md-4"
          :error="(current_team_id!=null) ? false : true"
          :rules="(current_calendar=='TEAM') ? [(v) => !!v || 'Team is required'] : []"
          @change="onTeam" 
          return-object
        ></v-select>


        <template v-if="current_calendar == 'LIBRARY'">
          <v-select  v-model="assign_library_program_id" :items="library_program"
            item-text="name" item-value="id" item-color="avidocolor" label="Select Library Program" color="avidocolor"
            class="pa-md-4" :error="(assign_library_program_id == 0) ? true : false"
            :rules="(current_calendar == 'LIBRARY') ? [(v) => !!v || 'Library Program is required'] : []" return-object>
          </v-select>

          <v-dialog ref="dialog" v-model="assignprogram_startdate" :return-value.sync="assign_program_startdate"
            persistent width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field color="avidocolor" class="pa-md-4" v-model="assign_program_startdate"
                label="Select Program Start Date" readonly v-bind="attrs" v-on="on"
                :error="(assign_program_startdate == null) ? true : false"></v-text-field>
            </template>
            <v-date-picker v-model="assign_program_startdate" color="avidocolor black--text" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="avidocolor" @click="assignprogram_startdate = false">
                Cancel
              </v-btn>
              <v-btn text color="avidocolor" @click="$refs.dialog.save(assign_program_startdate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </template>
  
        <v-select
          v-if="current_calendar!='TEAM'"
          :disabled="(current_calendar=='AVIDO') ? false : true"
          v-model="program_type"
          :items="program_type_option"
          item-color="avidocolor"
          label="Sport or Goal"
          color="avidocolor"
          class="pa-md-4"
          :error="(program_type=='') ? true : false"
          :rules="(current_calendar=='AVIDO') ? [(v) => !!v || 'Sport or Goal is required'] : []"
           @change="onChangeProgramType()" 
        ></v-select>

        <v-select
          v-if="(current_calendar=='AVIDO')"
          v-model="goal_sport_id"
          :items="(program_type=='SPORT') ? sport_option : goal_option"
          item-text="name"
          item-value="id"
          item-color="avidocolor"
          :label="(program_type=='SPORT') ? 'Select Sport' : 'Select Goal'"
          color="avidocolor"
          class="pa-md-4"
          :rules="goalSportRules"
          :error="(goal_sport_id>0) ? false : true"
          clearable
        >
        </v-select>

        <v-select
          v-if="goal_sport_id>0 && position_option[goal_sport_id] !== undefined "
          :disabled="((current_calendar=='AVIDO' && program_type=='SPORT') || current_calendar=='TEAM')  ? false : true"
          v-model="position_id"
          :items="position_option[goal_sport_id]"
          item-text="name"
          item-value="position_filter_id"
          item-color="avidocolor"
          label="Position"
          color="avidocolor"
          class="pa-md-4"
        ></v-select>

        <v-select
          :disabled="(current_calendar=='LIBRARY') ? true : false"
          v-model="target1_muscle_id"
          :items="muscles_option"
          item-text="name"
          item-value="id"
          item-color="avidocolor"
          label="Target Muscle 1 (optional)"
          color="avidocolor"
          class="pa-md-4"
          clearable
        ></v-select>

        <v-select
          :disabled="(current_calendar=='LIBRARY') ? true : false"
          v-model="target2_muscle_id"
          :items="muscles_option"
          item-text="name"
          item-value="id"
          item-color="avidocolor"
          label="Target Muscle 2 (optional)"
          color="avidocolor"
          class="pa-md-4"
          clearable
        ></v-select>

        <v-select
         :disabled="(current_calendar=='AVIDO') ? false : true"
          v-model="no_workouts_per_week"
          :items="no_workouts_per_week_option"
          item-color="avidocolor"
          label="Workouts Per Week"
          color="avidocolor"
          class="pa-md-4 pb-md-0"
          :error="(no_workouts_per_week=='') ? true : false"
          :rules="(current_calendar=='AVIDO') ? [(v) => !!v || 'Workout Per Week  is required'] : []"
        ></v-select>

        <v-row class="pl-md-4">
          <v-col cols="7" class="pb-0 text-left">
            <v-label>Available Equipment</v-label>
          </v-col>
          <v-col cols="5">
            <v-checkbox
              v-model="selectAll"
              :label="'Select All'"
              :disabled="(current_calendar=='AVIDO') ? false : true"
              @change="toggleSelectAll"
              color="avidocolor"
              hide-details
              class="mt-0 pr-md-4 float-right"
            ></v-checkbox>
          </v-col>
        </v-row>
        
        <v-row class="pl-md-12">
          <v-col
            cols="6"
            sm="2"
            md="2"
            v-for="equp_item in equipment_option"
            :key="equp_item.id"
          >
            <v-checkbox
              :disabled="(current_calendar!='AVIDO') ? true : (equp_item.id==15 ? true : false)"
              v-model="equipment_ids"
              :label="equp_item.name"
              :value="equp_item.id"
              :checked="true"
              color="avidocolor"
              hide-details
              class="mt-0"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-dialog
          ref="programstart_dialog"
          v-model="programstart_modal"
          :return-value.sync="program_start_date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :disabled="(current_calendar=='AVIDO') ? false : true"
              :value="formateDates('PS')"
              label="Program Start Date"
              readonly
              v-bind="attrs"
              v-on="on"
              color="avidocolor"
              class="pa-md-4 pt-md-8"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="program_start_date"
            scrollable
            color="avidocolor black--text"
          >
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="programstart_modal = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="avidocolor"
              @click="
                $refs.programstart_dialog.save(
                  program_start_date
                )
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <template  v-if="program_type=='SPORT'">
          <v-dialog
            ref="sport_inseason_start_dialog"
            v-model="sport_inseason_start_modal"
            :return-value.sync="sport_inseason_start_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="(current_calendar=='AVIDO') ? false : true"
                :value="formateDates('SPINS')"
                label="In-Season Start Date (optional)"
                readonly
                clearable
                @click:clear="sport_inseason_start_date = null"
                v-bind="attrs"
                v-on="on"
                color="avidocolor"
                class="pa-md-4"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="sport_inseason_start_date"
              scrollable
              color="avidocolor black--text"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="error"
                @click="sport_inseason_start_modal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="avidocolor"
                @click="
                  $refs.sport_inseason_start_dialog.save(
                    sport_inseason_start_date
                  )
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <v-dialog
            ref="sport_inseason_end_dialog"
            v-model="sport_inseason_end_modal"
            :return-value.sync="sport_inseason_end_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="(current_calendar=='AVIDO') ? false : true"
                :value="formateDates('SPINE')"
                label="In-Season End Date (optional)"
                readonly
                v-bind="attrs"
                v-on="on"
                color="avidocolor"
                class="pa-md-4"
                clearable
                @click:clear="sport_inseason_end_date = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="sport_inseason_end_date"
              scrollable
              color="avidocolor black--text"
              :min="today"
            >
              <v-spacer></v-spacer>
              <v-btn text color="error" @click="sport_inseason_end_modal = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="avidocolor"
                @click="
                  $refs.sport_inseason_end_dialog.save(
                    sport_inseason_end_date
                  )
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </template>

        <v-dialog
          v-if="program_type=='GOAL'"
          ref="goal_end_date_dialog"
          v-model="goal_end_date_modal"
          :return-value.sync="goal_end_date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :disabled="(current_calendar=='AVIDO') ? false : true"
              :value="formateDates('GE')"
              label="Goal End Date"
              readonly
              clearable
              v-bind="attrs"
              v-on="on"
              color="avidocolor"
              class="pa-md-4"
              @click:clear="goal_end_date = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="goal_end_date"
            scrollable
            color="avidocolor black--text"
            :min="today"
          >
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="goal_end_date_modal = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="avidocolor"
              @click="
                $refs.goal_end_date_dialog.save(goal_end_date)
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
          
        <v-footer color="black" fixed padless>
          <v-row justify="center" no-gutters>
            <v-btn type="submit"  class="my-2 avidocolor--text" text>
              <v-icon>mdi-content-save</v-icon>Save
            </v-btn>
          </v-row>
        </v-footer>
      </v-card>
      
      <div class="clear"></div>
      
      </v-form>

  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import { format, parseISO } from 'date-fns'

export default {
  name: "Trainingpreferences",
  data: () => ({
    today: new Date().toISOString().substr(0, 10),
    valid: false,
    selectAll: false,
    assignprogram_startdate: false,
    programstart_modal: false,
    sport_inseason_start_modal: false,
    sport_inseason_end_modal: false,
    goal_end_date_modal: false,
    goalSportRules: [],
  }),
  computed:{
    ...mapFields('trainingPreferences',{
      current_calendar:'userTrainingState.current_calendar',
      program_type:'userTrainingState.program_type',
      program_start_date:'userTrainingState.program_start_date',
      sport_inseason_start_date:'userTrainingState.sport_inseason_start_date',
      sport_inseason_end_date:'userTrainingState.sport_inseason_end_date',
      goal_end_date:'userTrainingState.goal_end_date',
      no_workouts_per_week:'userTrainingState.no_workouts_per_week',
      goal_sport_id:'userTrainingState.goal_sport_id',
      position_id:'userTrainingState.position_id',
      equipment_ids:'userTrainingState.equipment_ids',
      target1_muscle_id:'userTrainingState.target1_muscle_id',
      target2_muscle_id:'userTrainingState.target2_muscle_id',
      current_team_id:'userTrainingState.current_team_id',
      current_library_program_id:'userTrainingState.current_library_program_id',
      current_calendar_option:'current_calendar_option',
      no_workouts_per_week_option:'no_workouts_per_week_option',
      goal_option:'goal_option',
      sport_option:'sport_option',
      position_option:'position_option',
      muscles_option:'muscles_option',
      equipment_option:'equipment_option',
      program_type_option:'program_type_option',
      athlete_team:'athlete_team',
      library_program:'library_program',
    }),
    ...mapFields("workoutCalendar", {
      get_for_user_id:'get_for_user_id',
      get_for_team_id:'get_for_team_id',
      assign_library_program_id: "assign_library_program_id",
      assign_program_startdate: "assign_program_startdate",
    }),
  },
  methods: {
    ...mapActions('trainingPreferences',{
      getTrainingModel:'getTrainingModel',
      updateTrainingModel:'updateTrainingModel',
    }),
    ...mapActions("workoutCalendar", {
      addLibraryProgramToCalendar: "addLibraryProgramToCalendar",
    }),
    formateDates(datetype) {
      if(datetype=='PS'){
        return this.program_start_date ? format(parseISO(this.program_start_date), 'MM-dd-yyyy') : ''
      }else if(datetype=='SPINS'){
        return  this.sport_inseason_start_date ? format(parseISO(this.sport_inseason_start_date), 'MM-dd-yyyy') : ''
      }else if(datetype=='SPINE'){
        return this.sport_inseason_end_date ? format(parseISO(this.sport_inseason_end_date), 'MM-dd-yyyy') : ''
      }else if(datetype=='GE'){
        return this.goal_end_date ? format(parseISO(this.goal_end_date), 'MM-dd-yyyy') : ''
      }
    },
    onChangeProgramType: function () {
      this.goal_sport_id=0
      this.position_id=0
      let sptype=this.program_type.toLowerCase()
      let gsmeg=sptype.charAt(0).toUpperCase()+ sptype.slice(1) +' is required'
      this.goalSportRules=[gsmeg]
    },
    onTeam: function (model) {
      this.current_team_id=model.id
      this.goal_sport_id=model.program_type_id
    },
    toggleSelectAll() {
      if (this.selectAll) {
        this.equipment_ids = this.equipment_option
          .map(equp_item => equp_item.id);
      } else {
        this.equipment_ids = [15];
      }
    },    
    onSubmit: function () {
      if (this.$refs.trainingpreferencesform.validate()) {
        if (this.current_calendar == "LIBRARY") {
          this.addLibraryProgramToCalendar();
        } else {
          this.updateTrainingModel();
        }
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
  },
  created() {

    if(this.equipment_option.length==this.equipment_ids.length){
      this.selectAll=true
    }
    this.$store.commit("workoutCalendar/resetLPROGRAMState");
    this.getTrainingModel();
  },
  watch:{
    goal_sport_id:function(){
      if(this.goal_sport_id>0){
        this.goalSportRules=[]
      }else{
        this.goalSportRules=['Sport is required']
      }
    }
  },
};
</script>


