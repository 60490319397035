te<template>
  <div class="teamProfile">

    <template v-if="manage_roaster==undefined">
    <v-row align="center" justify="center">
      <v-col>
        <v-avatar class="elevation-10" size="200">
          <v-img :alt="team_name" :src="(profile_url!=null)?profile_url:'/images/logo.jpg'" @error="profile_url='/images/logo.jpg'"></v-img>
        </v-avatar>
        <br>
        <v-btn v-if="team_organization_name!=null" small outlined class="my-2  white--text font-weight-bold">
          {{team_organization_name}}
        </v-btn>
      </v-col>
    </v-row>

    <v-card color="black" class="pa-0 rounded-lg" v-if="(is_teammember==true || is_teamcoach==true) && team_description!='null' && team_description!=null">  
      <v-list-item>
        <v-list-item-content class="text-left align-self-start">
          <v-list-item-title>
            <span class="text-wrap">
              {{team_description}}
            </span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </template>

  <template v-if="(is_teammember==true || is_teamcoach==true)">
   <div class="text-left text-body-1 pt-2">
    <span v-if="is_teamcoach==true">Leader Board</span>
    <span v-else-if="is_teammember==true && leaderBoardResults.length>0">Leader Board</span>
    <v-btn @click="editLBoard" v-if="is_teamcoach" rounded small class="float-right avidocolor black--text font-weight-bold" >
      Edit
    </v-btn>
    </div>

    <v-slide-group
      v-model="activeSlide" 
      center-active
      style="width: 100%;"
    >
      <v-slide-item
        v-for="(lbResult, lbindex) in leaderBoardResults"
        :key="lbindex"
      >
        
        <v-card
          class="ma-1"
          color="grey-lighten-1"
          width="150"
          @click="viewTeamLeaderBoard(lbResult)"
        >
          <v-row
            align="center"
            justify="center"
            class="ma-0"
          >
          <v-col class="leader-wrap">
              {{ lbResult.exercise_name }}  
            </v-col>
          </v-row>

          <v-row
            align="center"
            justify="center"
            class="ma-0 avidocolor"
            style="height: 2em" 
          >
            <v-col class="pa-0">
              <span class="black--text font-weight-black">
                {{ lbResult.result }} {{ (lbResult.result_type=='MAXREPS') ? 'reps' : 'lbs' }}
              </span>
            </v-col>
          </v-row>

          <v-row
            align="center"
            justify="center"
            class="ma-0"
            style="height: 4em" 
          >
            <v-col>
              <span class="text-body-1 font-weight-thin">
                {{ lbResult.user_name }}
              </span>
            </v-col>
          </v-row>

        </v-card>
      </v-slide-item>
    </v-slide-group>  
  </template>

      <template v-if="team_coaches.length > 0">
        <div class="text-left text-body-1 pt-2">Coaches</div>
          <template v-for="(user, i) in team_coaches">
            <v-card  class="rounded-lg mb-1" :key="i">
              <v-list-item
                @click="getUserProfile(user.id,user.name)"
              >
                <v-list-item-avatar size=50>
                  <v-img :alt="user.name" :src="(user.profile_pic!=null)?user.profile_pic:'/images/logo.jpg'" @error="user.profile_pic='/images/logo.jpg'"></v-img>
                </v-list-item-avatar>
                
                <v-list-item-content class="text-left">
                  <v-list-item-title v-text="user.name"></v-list-item-title>
                </v-list-item-content>

                <v-list-item-action v-if="manage_roaster==1">
                  <v-row>
                    <v-col class="pa-0">
                      <v-btn icon dark @click.stop="deleteTeamCoach(i, user.id)">
                        <v-icon color="red">mdi-minus-circle</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-action>
              </v-list-item>
            </v-card>     
          </template>  

        <v-dialog v-model="coach_dialog" max-width="300">
          <v-card>
            <v-card-title>
              Are you sure you would like to delete this coach from team?
            </v-card-title>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="coach_dialog = false">
                No
              </v-btn>

              <v-btn color="avidocolor" text @click.stop.prevent="conformDeleteTeamCoach">
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <template v-if="team_members.length > 0">
          <div class="text-left text-body-1 pt-2">Team Members</div> 
          <template v-for="(user, mindex) in team_members">
            <v-card  class="rounded-lg mb-1" :key="mindex+'_'+user.id">
              <v-list-item
                  @click="getUserProfile(user.id,user.name)"
              >

                <v-list-item-avatar  size=50>
                <v-img  :alt="user.name" :src="(user.profile_pic!=null)?user.profile_pic:'/images/logo.jpg'" @error="user.profile_pic='/images/logo.jpg'"></v-img>
                </v-list-item-avatar>
                
                <v-list-item-content class="text-left">
                  <v-list-item-title v-text="user.name"></v-list-item-title>
                </v-list-item-content>

                <v-list-item-action v-if="manage_roaster==1">
                  <v-row>
                    <v-col class="pa-0">
                      <v-btn icon dark @click.stop="deleteTeamMember(mindex, user.id)">
                        <v-icon color="red">mdi-minus-circle</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-action>
              </v-list-item>
            </v-card> 
        </template>  

        <v-dialog v-model="member_dialog" max-width="300">
          <v-card>
            <v-card-title>
              Are you sure you would like to delete this member from team?
            </v-card-title>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="member_dialog = false">
                No
              </v-btn>

              <v-btn color="avidocolor" text @click.stop.prevent="conformDeleteTeamMember">
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <div class="clear"></div>
      <v-footer color="black" fixed padless  v-if="manage_roaster==undefined">
        <v-row justify="center" no-gutters>
            <template v-if="is_teammember==true || is_teamcoach==true">
              <v-btn @click="getTeamWorkout(team_id)"  class="my-2 avidocolor--text" text >
                <v-icon>mdi-dumbbell</v-icon>Workouts
              </v-btn>

              <v-btn @click="getTeamResult(team_id)"  class="my-2 avidocolor--text" text >
                <v-icon>mdi-chart-bar</v-icon>Results
              </v-btn>
            </template>

            <!-- <v-btn  v-if="fromseacrh==1" to="/jointeam"  class="my-2 avidocolor--text" text > -->
            <v-btn to="/jointeam"  v-if="is_teammember==false && is_teamcoach==false" class="my-2 avidocolor--text" text >
              <v-icon>mdi-account-multiple-plus</v-icon>&nbsp; Join Team
            </v-btn>
        </v-row>
      </v-footer>

      
  </div>
</template>

<style>
.leader-wrap {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* Ensure normal wrapping */
  height: 4em
}
</style>

<script>
import { mapActions } from "vuex";
import {mapFields  } from "vuex-map-fields";

export default {
  name: "TeamProfile",
  data () {
    return {
      coach_dialog: false,
      member_dialog: false,
      manage_roaster:this.$route.query.mr,
      deleted_tcindex: 0,
      deleted_tmindex: 0,
      deleted_coach_user_id:0,
      deleted_member_user_id:0,
      activeSlide: 0
    }
  },
  computed:{
    ...mapFields('teamProfile',{
      team_id:'team_id',
      team_name:'team_name',
      profile_url:'profile_url',
      team_description:'team_description',
      team_organization_name:'team_organization_name',
      team_coaches:'team_coaches',
      team_members:'team_members',
      is_teammember:'is_teammember',
      is_teamcoach:'is_teamcoach',
      leaderBoardResults:'leaderBoardResults',
      leaderBoard_team_id:'leaderBoard_team_id',
      leaderBoard_team_name:'leaderBoard_team_name',
      viewleaderBoard:'viewleaderBoard',
    }),
    ...mapFields('results',{
      result_team_id:'team_id',
    }),  
    ...mapFields('workoutCalendar',{
      calendar_date:'calendar_date'
    }), 
  },
  methods: {
    ...mapActions('team', {
      leaveTeamAsCoachModel: 'leaveTeamAsCoachModel',
      leaveTeamAsTeamMember:'leaveTeamAsTeamMember'
    }),
    ...mapActions('teamProfile',{
      getTeamProfileModel:'getTeamProfileModel',
      viewTeamLeaderBoard:'viewTeamLeaderBoard',
    }),
    getUserProfile(userid,username) {
      this.$router.push({ path: 'user-profile?uid='+userid+'&uname='+username });
    },  
    getTeamWorkout(teamid) {
      this.calendar_date=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.$router.push({ path: 'week-summary?uid=0&tid='+teamid });
    },    
    getTeamResult(teamid) {
      this.result_team_id = teamid;
      this.$router.push({ path: 'results' });
    },
    deleteTeamCoach(tcindex, coach_user_id) {
      this.deleted_tcindex = tcindex
      this.deleted_coach_user_id = coach_user_id
      this.coach_dialog = true
    },
    conformDeleteTeamCoach() {
      this.coach_dialog = false
      this.leaveTeamAsCoachModel({ 'remove_index': this.deleted_tcindex, 'team_id': this.team_id, 'delete_coach_user_ids': this.deleted_coach_user_id, 'call_from' : 'MANAGEROASTER' })
    },    
    deleteTeamMember(tmindex,member_user_id) {
      this.deleted_tmindex = tmindex
      this.deleted_member_user_id = member_user_id
      this.member_dialog = true
    },
    conformDeleteTeamMember() {
      this.member_dialog = false
      this.leaveTeamAsTeamMember({ 'remove_index': this.deleted_tmindex, 'team_id': this.team_id, 'delete_member_user_ids': this.deleted_member_user_id, 'call_from' : 'MANAGEROASTER'  })
    },
    editLBoard() {
      this.leaderBoard_team_id=this.team_id
      this.$router.push({ path: 'edit-leaderboard' });
    },    
  },
 /*  mounted() {
    setInterval(() => {
      this.activeSlide = (this.activeSlide + 1) % 20; // 20 is the total number of slides
    }, 2000); // Auto-slide every 1 second
  },  */
  created() {
    this.profile_url=null
    this.getTeamProfileModel();
  },
};
</script>