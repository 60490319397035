<template>
<div class="EditLeaderBoard">
  <v-row class="mb-8"> 
  <v-col>

      <v-autocomplete 
        v-model="selected_exercise_id" 
        color="avidocolor" 
        label="Start typing to Search Exercise" 
        :items="avido_exercise_list" 
        item-text="exercise_name"
        item-value="exercise_id"
        return-object
        @change="exerciseToLeaderBoard" 
        :menu-props="{closeOnContentClick:true}"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip color="avidocolor--text" v-if="index === 0 && selected_exercise_id.length==1">
            <span>{{ item.exercise_name }}</span>
          </v-chip>
        </template>

        <template v-slot:item="data">
          <v-list-item-avatar tile size=50>
            <v-img class="rounded" :src="
              data.item.exercise_image_thumbnail != null
                ? data.item.exercise_image_thumbnail
                : '/images/logo.jpg'
            " @error="data.item.exercise_image_thumbnail = '/images/logo.jpg'"></v-img>
          </v-list-item-avatar>

          <v-list-item-content class="text-left">
            <v-list-item-title v-html="data.item.exercise_name" class="pl-2"></v-list-item-title>
          </v-list-item-content>
          
        </template>
      </v-autocomplete>


      <template v-if="(leaderBoards.length>0)" >
            <div class="text-left text-body-1 pb-2">Leader Boards</div> 
              <draggable
                v-model="leaderBoards"
                class="list-group"
                handle=".handle"
                ghost-class="grey"
                @start="dragging = true"
                @end="dragging = false"
                color="grey darken-4"
              >
                <div
                  class="list-group-item pb-2"
                  v-for="(model,cindex) in leaderBoards"
                  :key="cindex"
                >
                <v-card class="rounded-lg" :key="cindex">

<v-list-item>
    <v-list-item-action class="mr-2">
      <v-row>
        <v-col>
          <v-btn
            icon
            color="red"
            dark
            @click.stop.prevent="
              deleteLeaderBoard(cindex)
            "
          >
            <v-icon color="red">mdi-minus-circle</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-list-item-action>

    <v-list-item-avatar size=50>
        <v-img 
          :alt="model.exercise_name"
          :src="
            model.image != null ? model.image : '/images/logo.jpg'
          "
          @error="model.image = '/images/logo.jpg'"
        ></v-img>
    </v-list-item-avatar>

    <v-list-item-content class="pl-2 text-left">
      <v-row  align="center">
        <v-col class="py-0" :cols="$vuetify.breakpoint.xs ? 12 : 6"><v-list-item-title  v-html="model.exercise_name" :class="$vuetify.breakpoint.xs ? 'pt-3 wrap-text' : 'wrap-text'"></v-list-item-title></v-col>
        <v-col class="py-0" :cols="$vuetify.breakpoint.xs ? 12 : 6">
        <v-select 
        v-model="leaderBoards[cindex].result_type"
        :items="leaderBoarder_resultitems"
        item-text="title"
        item-value="value"
        color="avidocolor"
        required
        :class="$vuetify.breakpoint.xs ? 'py-0' : null"
        ></v-select>
        </v-col>
      </v-row>
    </v-list-item-content>

    <v-list-item-action>
      <v-row>
        <v-col class="pa-0">
          <v-btn
            icon
            color="avidocolor"
            dark
            class="handle"
          >
            <v-icon color="avidocolor">mdi-drag</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-list-item-action>
</v-list-item>


             <!--      <v-simple-table>
                    <tbody>
                  <tr :key="cindex">
                    <td style="width: 10%;">              
                      <v-btn
                        icon
                        color="red"
                        dark
                        @click.stop.prevent="
                          deleteLeaderBoard(cindex)
                        "
                      >
                        <v-icon color="red">mdi-minus-circle</v-icon>
                      </v-btn>
                    </td>
                    <td  class="text-left" style="width: 50%;">
                      <v-avatar tile size=50>
                        <v-img class="rounded" 
                          :alt="model.exercise_name"
                          :src="
                            model.image != null ? model.image : '/images/logo.jpg'
                          "
                          @error="model.image = '/images/logo.jpg'"
                        ></v-img>
                      </v-avatar>
                      <span class="pl-2 v-list-item__title">{{ model.exercise_name }}</span>
                    </td>

                    <td style="width: 30%;">
                      <v-select 
                        v-model="leaderBoards[cindex].result_type"
                        :items="leaderBoarder_resultitems"
                        item-text="title"
                        item-value="value"
                        color="avidocolor"
                        required
                        ></v-select>
                    </td>

                    <td style="width: 10%;">                    
                      <v-btn
                        icon
                        color="avidocolor"
                        dark
                        class="handle"
                      >
                        <v-icon color="avidocolor">mdi-drag</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  </tbody>  
                </v-simple-table> -->
              </v-card>
            </div>
          </draggable>

        <v-footer color="black" fixed>
          <v-row justify="center" no-gutters>
            <v-btn @click="saveTeamLeaderBoard" class="avidocolor--text" text>
              <v-icon>mdi-content-save</v-icon>save
            </v-btn>
          </v-row>
        </v-footer>

   </template>
       
  </v-col>
  </v-row>
  </div>  
</template>



<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import draggable from 'vuedraggable';

export default {
  name: "EditLeaderBoard",
  components: {
    draggable
  },
  data () {
    return {
      selected_exercise_id: {},
    }
  },
  computed:{
    ...mapFields("getDataList", {
        avido_exercise_list: "avido_exercise_list"
    }),
    ...mapFields('teamProfile',{
      leaderBoards:'leaderBoards',
      leaderBoard_team_id:'leaderBoard_team_id',
      leaderBoarder_resultitems:'leaderBoarder_resultitems',
    }) 
  },
  methods: {
    ...mapActions('teamProfile',{
      saveTeamLeaderBoard:'saveTeamLeaderBoard',      
    }),    
    ...mapActions('getDataList',{
      getExerciseList:'getExerciseList',      
    }),
    exerciseToLeaderBoard(model){      
      let leaderBoard = {
        exercise_id: model.exercise_id,
        exercise_name: model.exercise_name,
        exercise_image_thumbnail: model.exercise_image_thumbnail,
        result_type: 'MAXWEIGHT',
      }
      this.leaderBoards.push(leaderBoard)
      this.selected_exercise_id={}
    },
    deleteLeaderBoard(index) {
      if (index >= 0) this.leaderBoards.splice(index, 1);
    }    
  },
  created() {
    if(this.avido_exercise_list.length==0){
      this.getExerciseList();
    }
  },
};
</script>