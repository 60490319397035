import router from '../../router'
import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    setall_workout_type: 'CALENDAR',  // CALENDAR/LIBRARY
    allSets: [{reps:0,weight:0,checkmark_status:false,percentage:0,rpe_level:0}],
    allSetsRepsType: 'REPS',
    allSetsWeightType: 'LBS',
    allSetsDisplayWeightType: 'LBS',
    allSetsRestTime: 0,
    allSetsRestTimeMin: 0,
    allSetsRestTimeSec: '00',
    allSetsTempo: null,
    allWFCSets_reps: 0,
    allWFCSets_reps_type: 'REPS',
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    reSetWFCModelState(state) {
      state.allWFCSets_reps= 0,
      state.allWFCSets_reps_type='REPS'
    },
    reSetModelState(state) {
      state.allSets= [{reps:0,weight:0,checkmark_status:false,percentage:0,rpe_level:0}],
      state.allSetsRepsType= 'REPS',
      state.allSetsWeightType= 'LBS',
      state.allSetsDisplayWeightType= 'LBS',
      state.allSetsRestTime= 0,
      state.allSetsRestTimeMin= 0,
      state.allSetsRestTimeSec= '00',
      state.allSetsTempo = null
    },
  },

  actions: {
    changeSets(){
      
      let workoutexerciselist=null
      let step=5
      let setallWorkoutType=this.state.setAllExerciseSet.setall_workout_type
      let set_allSets=this.state.setAllExerciseSet.allSets
      let set_allSetsRepsType=this.state.setAllExerciseSet.allSetsRepsType
      let set_allSetsWeightType=this.state.setAllExerciseSet.allSetsWeightType
      let set_allSetsRestTime=this.state.setAllExerciseSet.allSetsRestTime
      let set_allSetsTempo=this.state.setAllExerciseSet.allSetsTempo
    //  console.log(this.state.workoutExercise.exerciseList)

      let total_set_count=this.state.setAllExerciseSet.allSets.length
      if(setallWorkoutType=='CALENDAR'){
         workoutexerciselist=this.state.workoutExercise.exerciseList;
         this.state.workoutExercise.data_change=true
      }
      if(setallWorkoutType=='LIBRARY'){
         workoutexerciselist=this.state.libraryExercise.lexerciseList; 
         this.state.workoutExercise.library_edit_mode=true
      }
      

      /* if(workoutexerciselist!=null){
        for (let workoutexercise of Object.values(workoutexerciselist)) {
          let exeIndex=0
          for (let wexercise of Object.values(workoutexercise)) {
            let setIndex=0
            wexercise.exercise_set_count=total_set_count
            wexercise.sets=set_allSets
            wexercise.reps_type=set_allSetsRepsType
            wexercise.weight_type=set_allSetsWeightType
            wexercise.rest_time=set_allSetsRestTime 
          } 
        }
      } */

      if (workoutexerciselist != null) {
        workoutexerciselist.forEach(workoutexercise => {
          

          Object.values(workoutexercise).forEach(wexercise => {

            let finalSets=[]
            let setindex=0
            let setWeightType=set_allSetsWeightType
            let exer1RM = wexercise.user_one_rep_max != 0 ? wexercise.user_one_rep_max : wexercise.result_one_rep_max
            Object.values(set_allSets).forEach(sets => {

              let setWeight=0
              let setPercentage=0
              setWeight=sets.weight
              setPercentage=setWeight

              if(set_allSetsWeightType=='PERCENT' && setWeight>0){
                if(wexercise.zero_weight_label=='max' && exer1RM>0){
                  setWeightType='LBS'
                  setWeight=Math.round((exer1RM*setPercentage)/100)    
                  setWeight=(setWeight % 5 != 0) ? Math.ceil(setWeight / step) * step : setWeight
                }
              }

              let new_set = {
                checkmark_status: sets.checkmark_status,
                reps: sets.reps,
                set_id: wexercise.exercise_id+'_'+setindex,
                weight: setWeight,
                percentage: setPercentage,
                rpe_level: sets.rpe_level,
              }
              finalSets.push(new_set)
              setindex++
            });

            wexercise.exercise_set_count=total_set_count
            wexercise.sets=finalSets
            wexercise.reps_type=set_allSetsRepsType
            wexercise.weight_type=setWeightType
            wexercise.tempo=set_allSetsTempo
            wexercise.rest_time=set_allSetsRestTime
          });
        });
      }

      router.go(-1);
    },
    changeallWFCreps(){
      
      let wfcexerciselist=this.state.libraryExercise.wfc_lexrecise_array;
      let allWFCSetsReps=this.state.setAllExerciseSet.allWFCSets_reps;
      let allWFCSetsRepsType=this.state.setAllExerciseSet.allWFCSets_reps_type;

      if (wfcexerciselist != null) {
        wfcexerciselist.forEach(wfcexercise => {
            wfcexercise.reps=allWFCSetsReps
            wfcexercise.reps_type=allWFCSetsRepsType
        });
      } 
      console.log(wfcexerciselist)

      router.go(-1);
    }
  },

};