import axios from "axios";
import { getField,updateField } from 'vuex-map-fields';
import router from '../../router'

export default {
  namespaced: true,
  state: {
    is_workout_editable: 1, // 0 = for all , 1 for user who login , user coach and trainer
    calendar_type: 'AVIDO',  // LIBRARY,TEAM,AVIDO
    calendar_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    calendar_date_text: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toLocaleString('en-US', { month: 'short', day: '2-digit', timeZone: 'UTC' }),
    show_begin_workout: true, // 1 = for all , 0 for future workouts
    get_exercise: true,
    get_for_user_id: 0,
    get_for_team_id: 0,
    generate_user_workout: 0,
    change_equipment: false,
    current_equipments: [],
    current_cycle_id : 0,
    current_user_cycle_id : 0,
    current_cycle_image : null,
    current_cycle_name : null,
    current_cycle_description : null,
    current_workout_id: 0,
    current_cycle_week: 0,
    current_workout_name: null,
    current_workout_description: null,
    current_workout_image: null,
    cworkout_image: null,
    week_totals: [],
    calendar_workouts: [],
    user_LWorkouts_list:[],
    assign_library_workout_id:0,
    assign_workout_execution_dates: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    user_LCycles_list:[],
    assign_library_cycle_id: 0,
    assign_cycle_start_date: null,
    assign_cycle_end_date: null,
    user_LProgram_list:[],
    assign_library_program_id: 0,
    assign_program_startdate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    show_footer: false,
    calendar_edit_mode:false,
    copy_id: 0,
    copy_label: null,
    copy_name: null,
    personal_workout_name: null,
  },
  mutations: {
    unsetWeekSummary(state) {
      state.show_begin_workout = true,
      state.current_user_workout_id = 0,
      state.current_personal_uw_id = 0,
      state.current_deload_workout = false
      state.current_user_workout_completed = false
      state.generate_user_workout = 0,
      state.change_equipment = false,
      state.current_equipments = [],
      state.current_cycle_id = 0,
      state.current_cycle_week = 0,
      state.current_user_cycle_id = 0,
      state.current_cycle_image = null,
      state.current_cycle_name = null,
      state.current_cycle_description = null,
      state.current_workout_id = 0,
      state.current_workout_name = null,
      state.cworkout_image = null,
      state.current_workout_image = null,
      state.current_workout_description = null,
      state.week_totals = [],
      state.calendar_workouts = [],
      state.show_footer=false,
      state.calendar_edit_mode=false,
      state.copy_id= 0,
      state.copy_label= null,
      state.copy_name= null
      state.personal_workout_name= null
    //  state.calendar_date= (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    //  state.calendar_date_text= (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toLocaleString('en-US', { month: 'short', day: '2-digit', timeZone: 'UTC' })
    },

    setWeekSummary(state, response_data) {

      state.week_totals = response_data.week_totals
      state.calendar_workouts = response_data.calendar_workouts
      state.is_workout_editable = response_data.is_workout_editable
      if(response_data.current_displayskin!=null){
        state.current_workout_image = response_data.current_displayskin.logo_image
      }else{
        state.current_workout_image = '/images/logo.jpg'
      }
      
      
      state.calendar_date_text = (new Date(response_data.week_start_date)).toLocaleString('en-US', { month: 'short', day: '2-digit', timeZone: 'UTC' })+' - '+(new Date(response_data.week_end_date)).toLocaleString('en-US', { month: 'short', day: '2-digit', timeZone: 'UTC' })
      if(response_data.cycle_id>0){
          state.current_cycle_id = response_data.cycle_id
          state.current_cycle_image = response_data.cycle_image
          state.current_cycle_name = response_data.cycle_name
          state.current_cycle_description = response_data.cycle_description
          state.current_cycle_week = response_data.cycle_week
      }    
      if(response_data.library_cycle_id>0){
          state.current_cycle_id = response_data.library_cycle_id
          state.current_cycle_image = response_data.cycle_image
          state.current_cycle_name = response_data.cycle_name
          state.current_cycle_description = response_data.cycle_description
          state.current_cycle_week = response_data.cycle_week
      }

      if(response_data.is_workout_editable==true && (response_data.workout_editable_type=='OWNER' || response_data.workout_editable_type=='TRAINER' || response_data.workout_editable_type=='COACH')){
        state.show_footer=true
      }
      
    },

    setLWORKOUTState(state, response_data) {
      state.user_LWorkouts_list = response_data
    },
    resetLWORKOUTState(state) {
      state.assign_library_workout_id = 0
      state.assign_workout_execution_dates =  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      state.user_LWorkouts_list = []
    },
    
    setLCYCLEState(state, response_data) {
      state.user_LCycles_list = response_data
    },
    resetLCYCLEState(state) {
      state.user_LCycles_list = []
      state.assign_library_cycle_id = 0
      state.assign_cycle_start_date = null
      state.assign_cycle_end_date = null
    },

    setLPROGRAMState(state, response_data) {
      state.user_LProgram_list = response_data
    },
    resetLPROGRAMState(state) {
      state.user_LProgram_list = []
      state.assign_library_program_id = 0
      state.assign_program_startdate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    },
    updateField
  },
  getters: {
    getField,
  },
  actions: {
    getWeekSumaryModel({ commit, dispatch }) {
      return new Promise((resolve,reject) => {
        let api_name='get_week_summary' 
        let api_data={
          get_for_user_id: this.state.workoutCalendar.get_for_user_id,
          get_for_team_id: this.state.workoutCalendar.get_for_team_id,
          calendar_date: this.state.workoutCalendar.calendar_date
        };

        axios({
          method: 'GET',
          url: api_name,
          params:api_data
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("unsetWeekSummary")
              commit("setWeekSummary", response_data);
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },
 
    deleteCalendarModel({ dispatch },model) {
      return new Promise((resolve, reject) => {

        this.api_name='delete_calendar_workout';
        this.api_data={
          calendar_uw_id: model.delete_calendar_id
        };

        axios({
          method: 'POST',
          url: this.api_name,
          params: this.api_data
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const resp_message = resp.data.message
              
              dispatch("setNotification", {
                display: true,
                text: resp_message,
                alertClass: "success"
              }, { root: true });        
              
              this.state.workoutCalendar.calendar_workouts.splice(model.remove_index, 1);      

              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },
    
 
    saveCopyCalendar({ dispatch }) {
      return new Promise((resolve,reject) => {
        axios({
          method: 'POST',
          url: 'copy_calendar_workout',
          data: {
            copy_calendar_uw_id: this.state.workoutCalendar.copy_id,
            workout_name: this.state.workoutCalendar.copy_name,
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const success_mesg = resp.data.message
              dispatch("setNotification", {
                display: true,
                text: success_mesg,
                alertClass: "success"
              }, { root: true })
              
              this.state.workoutCalendar.calendar_edit_mode=false
              this.state.library.library_workout_list=[]
              router.go(-1);
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    }, 
 
    savePersonalWorkout({ dispatch }) {
      return new Promise((resolve,reject) => {
        axios({
          method: 'POST',
          url: 'add_blank_workout',
          data: {
            workout_name: this.state.workoutCalendar.personal_workout_name,
            team_id: this.state.workoutCalendar.get_for_team_id,
            user_id: this.state.workoutCalendar.get_for_user_id,
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const success_mesg = resp.data.message
              dispatch("setNotification", {
                display: true,
                text: success_mesg,
                alertClass: "success"
              }, { root: true })

              router.go(-1);
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    }, 


    getUserLProgramList({ commit }) {
      return new Promise((resolve) => {
        axios({
          method: 'GET',
          url: 'get_user_library',
          params: {
            library_type: 'PROGRAM'
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response.library_data
              commit("setLPROGRAMState", response_data);
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              commit("resetLPROGRAMState");
            }
          })
      })
    },
    
    addLibraryProgramToCalendar({ dispatch }) {
      return new Promise((reject) => {
        axios({
          method: 'POST',
          url: 'assign_library_program',
          data: {
            addto_user_id: this.state.workoutCalendar.get_for_user_id,
            addto_team_id: this.state.workoutCalendar.get_for_team_id,            
            library_program_id: this.state.workoutCalendar.assign_library_program_id.id,            
            program_start_date: this.state.workoutCalendar.assign_program_startdate,            
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              dispatch("setNotification", {
                display: true,
                text: resp.data.message,
                alertClass: "success"
              }, { root: true })
              // router.push({ path: '/week-summary' });
              router.go(-1);
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    }, 
    
    getUserLCycleList({ commit }) {
      return new Promise((resolve) => {
        axios({
          method: 'GET',
          url: 'get_user_library',
          params: {
            library_type: 'CYCLE'
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response.library_data
              commit("setLCYCLEState", response_data);
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              commit("resetLCYCLEState");
            }
          })
      })
    },

   /*  addLibraryCycleToCalendar({ dispatch }) {
      return new Promise((reject) => {
        axios({
          method: 'POST',
          url: 'assign_library_cycle',
          data: {
            addto_user_id: this.state.workoutCalendar.get_for_user_id,
            addto_team_id: this.state.workoutCalendar.get_for_team_id,            
            library_cycle_id: this.state.workoutCalendar.assign_library_cycle_id.id,            
            cycle_start_date: this.state.workoutCalendar.assign_cycle_start_date,            
            cycle_end_date: this.state.workoutCalendar.assign_cycle_end_date,            
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              dispatch("setNotification", {
                display: true,
                text: resp.data.message,
                alertClass: "success"
              }, { root: true })
              router.go(-1);
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },  */

    
    getUserLWorkoutsList({ commit }) {
      commit("resetLWORKOUTState");
      return new Promise((resolve) => {
        axios({
          method: 'GET',
          url: 'get_user_library',
          params: {
            library_type: 'WORKOUT'
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response.library_data
              commit("setLWORKOUTState", response_data)
              router.push({ path: '/add-workout' })
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              commit("resetLWORKOUTState");
            }
          })
      })
    },
    
    addLibraryWorkoutToCalendar({ dispatch }) {
      return new Promise((reject) => {
        axios({
          method: 'POST',
          url: 'assign_library_workout',
          data: {
            addto_user_id: this.state.workoutCalendar.get_for_user_id,
            addto_team_id: this.state.workoutCalendar.get_for_team_id,            
            library_workout_id: this.state.workoutCalendar.assign_library_workout_id.id,            
            workout_execution_dates: this.state.workoutCalendar.assign_workout_execution_dates,            
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              dispatch("setNotification", {
                display: true,
                text: resp.data.message,
                alertClass: "success"
              }, { root: true })
              router.go(-1);
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    }, 

  },
};