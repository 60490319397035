<template>
  <div class="Home">

    <v-divider class="defaultcolor"></v-divider>

    <v-row class="pb-8 black">
      <v-col cols="12">
        <p class="pa-2 text-h4">
          AVIDO Creates Sport Specific Strength & Conditioning Programs For Athletes & Teams
        </p>
      </v-col>

      <v-col cols="12" md="6">
        <v-sheet outlined color="defaultcolor" class="rounded-xl">
          <v-card href="/athlete"  class="rounded-xl mx-auto black">
              <v-card-title class="justify-center">
                <p class="text-h4 defaultcolor--text">ATHLETES</p>
              </v-card-title>

              <v-img
                contain    
                src="/images/home_page/card_athlete.png"
              ></v-img>

              <v-card-text class="text-lg-h6 subtitle-1 text-left">
                <p>Train the right way with a program built for YOU & get the results you deserve.</p>
                <p class="pl-4">- Customized cycles & workouts </p>
                <p class="pl-4">- Tracking of every  rep & weight </p>
                <p class="pl-4">- View weeekly progress & results </p>
              </v-card-text>
            </v-card>
          </v-sheet>
      </v-col>
      <v-col cols="12" md="6">
        <v-sheet outlined color="defaultcolor" class="rounded-xl">
          <v-card  href="/coaches"  class="rounded-xl mx-auto black">
              <v-card-title class="justify-center">
                <p class="text-h4 defaultcolor--text">TEAMS/COACHES</p>
              </v-card-title>

              <v-img
                contain    
                src="/images/home_page/card_teamscoaches.png"
              ></v-img>

              <v-card-text class="text-lg-h6 subtitle-1 text-left">
                <p>Get the customized strength program that your team needs to reach the next level.</p>
                <p class="pl-4">- Send & track workouts remotely</p>
                <p class="pl-4">- Monitor training & progress </p>
                <p class="pl-4">- View individual & team results </p>
              </v-card-text>
            </v-card>
        </v-sheet>
      </v-col>
    </v-row>
  
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-card tile>
          <v-card-title class="justify-center">
            <p class="text-h4">
              Work Smarter & Maximize Your Results
              <v-divider class="defaultcolor"></v-divider>
            </p>
          </v-card-title>

         <v-sheet
            class="mx-auto"
          >
            <v-slide-group
              class="pa-4"
              show-arrows
            >
              <v-slide-item
                v-for="n in 6"
                :key="n"
              >
                <v-card
                  :width="$vuetify.breakpoint.xs ? 225 : 300"
                >
                  <v-img  
                    :src="'/images/home_page/carousel_'+n+'.png'"
                  ></v-img>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>

        </v-card>
      </v-col>
    </v-row>

    <v-row class="pb-4">
      <v-col cols="12" class="pa-0">
        <v-card tile class="black pt-4">
          <router-link to="/signup" class="text-h4 text-center avidocolor--text d-block d-md-inline">Sign Up For Your Free Account Today!</router-link>

          <!-- <v-card-title class="justify-center" @click="redirecttoreg">
            <a class="pa-2 signup-link text-h4 defaultcolor--text">
              Sign Up For Your Free Account Today!
            </a>
          </v-card-title> -->
          <p class="pa-6">Email teams@myavido.com to speack with a High School Strength & Conditioning Coach about our team strength training programs</p>
        </v-card>
      </v-col>
    </v-row>
    

    <FooterComponent />
    
  </div>
</template>

<style scoped>
.signup-link {
    text-decoration: none; /* Remove default underline */
    border-bottom: 3px solid #52F60C; /* Custom underline with specified thickness */
    padding-bottom: 2px; /* Space between text and underline */
}
</style>

<script>
import { mapGetters } from "vuex";
import FooterComponent from '@/components/FooterComponent.vue'

export default {
  name: "Home",
  components: {
    FooterComponent
  },
  ...mapGetters('user',{
    isTokenActive:'isTokenActive',
  }),
  methods: {
    redirecttoreg: function () {
      this.$router.push({ path: '/signup' });
    },
  },
};
</script>