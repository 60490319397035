<template>
  <div class="workoutexerciseequipment">


    <v-card class="pa-8 mx-auto" width="100%">
      <v-row class="pl-md-4">
        <v-col cols="7" class="pb-0 text-left">
          <v-label>Available Equipment</v-label>
        </v-col>
        <v-col cols="5">
          <v-checkbox
            v-model="wselectAll"
            :label="'Select All'"
            @change="wtoggleSelectAll"
            color="avidocolor"
            hide-details
            class="mt-0 pr-md-4 float-right"
          ></v-checkbox>
        </v-col>
      </v-row>


      <v-row class="pl-md-12">
        <v-col
          cols="6"
          sm="2"
          md="2"
          v-for="model in equipmentList"
          :key="model.id"
        >
          <v-checkbox
            :disabled="(model.id==15) ? true : false"
            :label="model.name"
            :value="model.id"
            color="avidocolor"
            hide-details
            class="mt-0"
            v-model="current_equipments"
          ></v-checkbox>
          <!-- @change="checkboxUpdated($event)" -->
        </v-col>
      </v-row>
    </v-card>

    <div class="clear"></div>
    <v-footer color="black" fixed padless>
      <v-row justify="center" no-gutters>
          <v-btn  @click="$router.go(-1)" class="my-2 avidocolor--text" text>
            <v-icon>mdi-chevron-left</v-icon>Back
          </v-btn>
          <v-btn  @click.stop="dialog = true"  class="my-2 avidocolor--text" text>
            Save
          </v-btn>
          <v-dialog
              v-model="dialog"
              max-width="600"
            >
              <v-card>
                <v-card-title>
                  Updating the equipment will overwrite this entire workout. Do you want to do this?
                </v-card-title>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="red"
                    text
                    @click="dialog = false"
                  >
                    No
                  </v-btn>

                  <v-btn
                    color="avidocolor"
                    text
                    @click.stop.prevent="updateEduipment()"
                  >
                    Yes
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
      </v-row>
    </v-footer>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "WorkoutEquipment",
  data: () => ({
    dialog: false,
    wselectAll: false,
  }),
  computed:{
    ...mapFields('workoutEquipment',[
      'equipmentList', 
    ]),
    ...mapFields('workoutCalendar',[
      'change_equipment', 
      'current_equipments', 
      'generate_user_workout', 
      'get_exercise', 
    ])
  },
  methods: {
    ...mapActions("workoutEquipment", {
      getModel: "getModel",
    }),
/*     ...mapActions("workoutExercise", {
      fromUpdateEquipment: "getModel",
    }), */
    checkboxUpdated(val) {
      if (val === null || val.length === 0) { // Custom checks in this
        // console.log('Unchecked')
      } else {
        // console.log('Checked')
      }
    },
    wtoggleSelectAll() {
      if (this.wselectAll) {
        this.current_equipments = this.equipmentList
          .map(equp_item => equp_item.id);
      } else {
        this.current_equipments = [15];
      }
    },
    updateEduipment(){
      this.generate_user_workout=1
      this.get_exercise=true
      this.change_equipment=true
      this.$router.go(-1) ;
    }
  },
  created() {
    let parameters = this.$route.query
    this.current_equipments=[15]
    this.getModel(parameters);
    if(this.equipmentList.length==this.current_equipments.length){
      this.wselectAll=true
    }
  },
};
</script>
