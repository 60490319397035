<template>
  <div class="setallwfcreps">

    <v-card class="mt-2">
        <v-row>
          <v-col cols="12">
            <h2 class="mb-3 d-inline">
              <span class="avidocolor--text">Set All Exercise</span>
            </h2>
          </v-col>
        </v-row>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="pa-0"> 
                  <div class="text-center font-weight-bold avidocolor--text text-body-1">
                    REPS
                  </div>
                </th>                
                <th class="pa-0">
                  <div class="text-center font-weight-bold avidocolor--text text-body-1">
                    REPS TYPE
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <!-- <td>
                  <v-btn icon color="red" dark @click.stop.prevent="
                  deletereps(index)
                  ">
                    <v-icon color="red">mdi-minus-circle</v-icon>
                  </v-btn>
                </td> -->

                <td style="width: 50%;">
                  <v-text-field
                    v-model="allWFCSets_reps"
                    :placeholder="placeholderString"
                    v-on:focus="repsFocus"
                    v-on:change="repsChange"
                    persistent-hint
                    class="setinput"
                    color="avidocolor"
                    type="number" 
                    min=0
                  ></v-text-field>
                </td>
                <td style="width: 50%;">
                  <div class="text-center">
                    <v-btn id="reps_type_menu"  class="font-weight-bold" text>
                      <div>{{ allWFCSets_reps_type }}</div>
                    </v-btn>
                    <v-menu offset-y activator="#reps_type_menu" open-on-hover>
                      <v-list>
                        <v-list-item v-for="(item, rindex) in reps_type_options" :key="rindex" :value="item.reps_type"
                          @click="changeRepsType(item)">
                          <v-list-item-title>{{ item.display_reps_type }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
    </v-card>

    <div class="clear"></div>
   <!--  <v-footer color="black" fixed padless>
      <v-row justify="center" no-gutters>
        <v-btn @click="addreps()" class="my-2 avidocolor--text" text>
          <v-icon>mdi-plus</v-icon> Add
        </v-btn>        
      </v-row>
    </v-footer> -->

  </div>
</template>


<style>
.v-application p {
  margin-bottom: 0px !important;
}

.pv-controls {
  padding: 5px;
}
</style>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "SetAllExerciseSet",
  data() {
    return {
      placeholderString: '',
      allstartingValue: null,
    }
  },
  computed: {
    ...mapFields('libraryExercise', [
      'reps_type_options',
    ]),    
    ...mapFields('setAllExerciseSet', [
      'allWFCSets_reps',
      'allWFCSets_reps_type',
    ]),
  },
  methods: {
    repsFocus(){
      this.placeholderString=(this.allWFCSets_reps!=null) ? this.allWFCSets_reps.toString() : ''
      this.allstartingValue=this.allWFCSets_reps
      this.allWFCSets_reps=null
    },    
    repsChange(){
      if(this.allWFCSets_reps==null && this.allstartingValue!=null){
        this.allWFCSets_reps=this.allstartingValue
      }
    },  
    changeRepsType(selected_repsType) {
      this.allWFCSets_reps_type = selected_repsType.reps_type
    },
   /*  addreps() {
      let current_set_count = this.allWFCSets.length
      let last_reps = 0
      let last_reps_type = 'REPS'
      if (current_set_count > 0) {
        last_reps = this.allWFCSets[current_set_count - 1].reps
        last_reps_type = this.allWFCSets[current_set_count - 1].reps_type
      }
      let new_set = {
        reps: last_reps,
        reps_type: last_reps_type,
      }
      this.allWFCSets.push(new_set)
    }, */
   /*  deletereps(remove_index) {
      this.allWFCSets.splice(remove_index, 1);
    }, */
  },
  created() {
    this.$store.commit("setAllExerciseSet/reSetWFCModelState");
  }
};
</script>
