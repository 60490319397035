<template>
  <div id="app">
    <v-app 
    :style="{ 'background':'#121212','background': `-webkit-linear-gradient(145deg, #121212 20%, ${darkBackgroundColor} 50%, ${lightBackgroundColor} 60%, #121212 75%)`,'background': `linear-gradient(145deg, #121212 20%, ${darkBackgroundColor} 50%, ${lightBackgroundColor} 60%, #121212 75%)`}"
    >
      <HeaderComponent msg="Welcome to Avido"/>
      <Notification />
      <Loader v-if="isLoading" />
       <v-container :fluid="component_name=='HOME' || component_name=='ATHLETE' || component_name=='COACHES' ? true : false" :style="component_name=='HOME' || component_name=='ATHLETE' || component_name=='COACHES' ? 'background: #121212;' : null">
          <router-view/>
       </v-container>

    </v-app>      
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderComponent from '@/components/HeaderComponent.vue'
import Notification from '@/components/Notification.vue'
import Loader from '@/components/Loader.vue'
import {initSession} from "./session-manager";
import { mapState } from 'vuex';

 export default {
    name: 'App',
    metaInfo: {
      title: 'AVIDO | Strength & Conditioning That Works',
      titleTemplate: 'AVIDO | Strength & Conditioning That Works',
      htmlAttrs: {
        lang: 'en-US'
      },
      meta: [
        { name: 'description', content: 'AVIDO creates free sport specific strength & conditioning programs for athletes and teams.  Workouts are customized and delivered remotely with exercise instructions and video. Reps and weights are logged to track results and optimize training.' },
        { name: 'keywords', content: 'high school strength and conditioning, high school strength training program, high school weight training program, high school speed agility quickness,sport specific,strength and conditioning for high school, strength and conditioning for football, strength and conditioning for basketball, strength and conditioning for soccer, strength and conditioning for rugby, strength and conditioning for wrestling, strength and conditioning for baseball, strength and conditioning for lacrosse, strength and conditioning for tennis, strength and conditioning for swimming, strength and conditioning for softball,strength and conditioning for golf, ' },
        // { name: 'viewport', content: 'width=device-width, initial-scale=1' }
      ],
    },
    components: {
      HeaderComponent,
      Notification,
      Loader
    },  
    computed: {
      ...mapState(['component_name','isLoading','darkBackgroundColor','lightBackgroundColor'])
    },
    mounted() {

      this.$vuetify.theme.themes.dark.avidocolor = (typeof(localStorage.getItem("darkTextColor")) != "undefined" && localStorage.getItem("darkTextColor") !== null) ? localStorage.getItem("darkTextColor") : "#52F60C"
      this.$vuetify.theme.themes.dark.avidosolidtextcolor = (typeof(localStorage.getItem("solidTextColor")) != "undefined" && localStorage.getItem("solidTextColor") !== null) ? localStorage.getItem("solidTextColor") : "#000000" 
      let dark_bg = (typeof(localStorage.getItem("darkBackgroundColor")) != "undefined" && localStorage.getItem("darkBackgroundColor") !== null) ? localStorage.getItem("darkBackgroundColor") : null 
      let light_bg = (typeof(localStorage.getItem("lightBackgroundColor")) != "undefined" && localStorage.getItem("lightBackgroundColor") !== null) ? localStorage.getItem("lightBackgroundColor") : null
      this.$store.commit('changeDarkBackgroundColor',dark_bg);
      this.$store.commit('changeLightBackgroundColor',light_bg);
      initSession();  //Initialize our session
    },
  }

</script>
     
<style>
.tbtn{
  text-transform: unset !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}

.headingclass
{
  height: 100vh; /*THis will set height equal to the height of windows*/
  width: 100vw; /*This will set width equal to width of windows*/
}

#nav a {
  color: #ffffff;
  padding: 0 10px;
  text-decoration: none;
}

.v-progress-circular__info{
  color: #ffffff;
}

#nav a.router-link-exact-active {
   color: #52F60C;
}

.v-list-item .v-list-item__subtitle, .v-list-item .v-list-item__title{
  white-space: normal;
}
.clear { clear: both; height: 200px; }

.onerepbtn.theme--dark.v-btn.v-btn--disabled, .theme--dark.v-btn.v-btn--disabled .v-btn__loading, .theme--dark.v-btn.v-btn--disabled .v-icon{ 
  color: white !important;
}
.rwbtn{
  color: hsla(0,0%,100%,.7);
}
.allSetCheckBox.v-input>.v-input__control>.v-input__slot{
  display: flow !important;
}
.setinput input {
    max-height: 32px !important;
    font-size: 1.2em !important;
    text-align: center !important;
}
.setinput.v-text-field>.v-input__control>.v-input__slot:before{
  border-color: transparent !important;
}
.setinput.v-text-field.v-input--is-disabled .v-input__slot:before{
  border-image: none !important;
}
.v-card__text, .v-card__title {
   word-break: break-word !important; 
}
.searchtable table>colgroup>:nth-child(1){
 width:5%;
}
.searchtable tbody tr.v-data-table__selected{
      color: #52F60C !important;
}
.v-slide-group__prev--disabled{
  display: none !important;
}
:focus-visible {
    outline: none;
}
/* 
*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
  visibility: hidden!important;
  opacity: 0 !important;
}

*::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
  visibility: hidden!important;
  opacity: 0 !important;
}

*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
  visibility: hidden!important;
  opacity: 0 !important;
} */

/* 
.v-bottom-navigation .v-btn {
  border-radius: 4px !important;
  height: 36px !important;
  font-size: initial  !important;
  text-transform: uppercase !important;
}
.v-bottom-navigation .v-btn:not(.v-btn--active) {
  color: initial !important;
  font-weight: bolder;
} */

</style>
