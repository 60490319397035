<template>
  <div class="editworkoutexercise">

    <v-card class="pa-2" v-if="current_user_workout_id>0 && (calendar_type=='PERSONAL' || calendar_type=='LIBRARY')">
      <v-text-field
        counter='50'
        v-model="current_workout_name"
        prepend-icon="mdi-pencil"
        name="name"
        label="Enter Name"
        color="avidocolor"
        v-on:change="data_change == false ? data_change = !data_change : data_change"
        :rules="[(v) => !!v || 'Name is required',(v) => !!v && v.length <= 50 || 'Max 50 characters allow.']"
      ></v-text-field>
  
      <v-textarea
        counter='500'
        v-model="current_workout_description"
        prepend-icon="mdi-pencil"
        name="description"
        color="avidocolor"
        v-on:change="data_change == false ? data_change = !data_change : data_change"
        :rules="[v => (v || '' ).length <= 500 || 'Description must be 500 characters or less']"
      >
        <template v-slot:label>
          <div>Description</div>
        </template>
      </v-textarea>
      

      <v-row>
        <v-col cols="5" md="2" class="pl-0">
          <v-icon>mdi-camera</v-icon> Select Image
        </v-col> 
        <v-col cols="7" md="10">
        <v-file-input
            @change="onFileChange"
            v-model="cworkout_image"
            id="image-upload"
            class="d-none"
          ></v-file-input>
          <v-avatar :color="(current_workout_image!=null) ? null : 'avidocolor'" class="rounded"  tile size="160"  @click="openUpload">
            <v-icon color="black" x-large :class="(current_workout_image!=null) ? 'd-none' : null">
              mdi-camera
            </v-icon>
            <v-img class="rounded" :alt="current_workout_name" 
              :class="(current_workout_image==null) ? 'd-none' : null"
              :src="(current_workout_image!=null) ? current_workout_image : '/images/logo.jpg'"
            @error="current_workout_image='/images/logo.jpg'"></v-img>
          </v-avatar>
        </v-col>
      </v-row>
    </v-card>

    <v-card color="black" class="rounded-lg" v-else>
        <v-container>
          <v-row align="center">
            <v-col cols="5" md="2"  class="pb-0">
              <v-avatar tile size="160">
                <v-img class="rounded" :alt="current_workout_name" 
                  :src="(current_workout_image!=null) ? current_workout_image : '/images/logo.jpg'"
                @error="current_workout_image='/images/logo.jpg'"></v-img>
              </v-avatar>
            </v-col>
            <v-col cols="7" md="10">
              <v-row>   
                <v-col cols="12" md="4" class="py-0">
                  <v-list-item>
                    <v-list-item-content class="py-0">
                      <v-list-item-subtitle class="text-uppercase">Sets</v-list-item-subtitle>
                      <v-list-item-title class="text-h5">{{ (is_completed!=undefined || end_workout!=undefined) ? completed_sets : total_sets }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>       
                <v-col cols="12" md="4" class="py-0">
                  <v-list-item>
                    <v-list-item-content class="py-0">
                      <v-list-item-subtitle class="text-uppercase">Reps</v-list-item-subtitle>
                      <v-list-item-title class="text-h5">{{(is_completed!=undefined || end_workout!=undefined) ? completed_reps : total_reps }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-list-item>
                    <v-list-item-content class="py-0">
                      <v-list-item-subtitle class="text-uppercase">Weight</v-list-item-subtitle>
                      <v-list-item-title class="text-h5">{{(is_completed!=undefined || end_workout!=undefined) ? completed_weight : total_weight }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="current_workout_description!='null'">
            <v-col class="mx-2 text-justify">
              {{current_workout_description}}
            </v-col>
          </v-row>
        </v-container>  
      </v-card>

      <div class="text-left text-body-1 pt-2">Warm Up</div> 
      <v-card class="rounded-lg mb-1">
        <v-list-item v-if="warmup!=null">
          <v-btn
            icon
            color="red"
            dark
            @click.stop.prevent="
              removeWFC('WARMUP')
            "
          >
            <v-icon color="red">mdi-minus-circle</v-icon>
          </v-btn>
          <v-list-item-avatar tile size=50>
            <v-img class="rounded" :alt="warmup.name" :src="(warmup.image!=null)?warmup.image:'/images/logo.jpg'" @error="warmup.image='/images/logo.jpg'"></v-img>
          </v-list-item-avatar>
          <v-list-item-content class="pl-2 text-left">
            <v-list-item-title v-if="warmup!=null" >{{warmup.name}}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="align-center ma-0">
            <v-list-item-avatar class="ma-0"  @click.stop.prevent="getLibraryModel(['WARMUP','ADDWARMUP',warmup.id])" >
              <v-icon medium class="avidocolor--text">mdi-shuffle-variant</v-icon>
            </v-list-item-avatar>
          </v-list-item-action>
        </v-list-item>   

        <v-list-item v-if="warmup==null" @click.stop.prevent="(current_workout_name!=null) ? getLibraryModel(['WARMUP','ADDWARMUP',0]) : null">
            <v-list-item-avatar>
              <v-icon class="avidocolor--text">mdi-plus-box</v-icon> 
            </v-list-item-avatar>
            <v-list-item-content  class="text-left">
              <v-list-item-title>Add WarmUp</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </v-card> 

        <div class="text-left text-body-1 pt-2 pb-2">
          Exercise
          <v-btn rounded small class="avidocolor black--text" @click="setallset" style="float: right;">
            Set All
          </v-btn>
        </div>

        <draggable v-model="exerciseList"
          handle=".handle"
          ghost-class="grey"
          @start="dragging = true"
          @end="dragging = false"
          v-if="
            (exerciseList.length>0)
          " 
          color="grey darken-4"
          @change="data_change==false ? data_change=!data_change : data_change"
          >
            <transition-group>
                  <div
                    class="list-group-item"
                    v-for="(exerciseListmodel,eindex) in exerciseList"
                    :key="'gindex-'+eindex"
                  >
                  <v-card class="rounded-lg mb-1" :key="eindex">
                  <template  v-if = "exerciseListmodel.length==1">
                    <template v-for="(model,mindex) in exerciseListmodel">
                      <v-list-item :key="mindex+model.user_workout_exercise_id+model.exercise_id" >
                        <v-btn
                          icon
                          color="red"
                          dark
                          @click.stop.prevent="
                            removeExercise(eindex,mindex)
                          "
                        >
                          <v-icon color="red">mdi-minus-circle</v-icon>
                        </v-btn>

                        <v-list-item-avatar tile size=50>
                          <v-img class="rounded" :alt="model.exercise_name" :src="(model.exercise_image_thumbnail!=null)?model.exercise_image_thumbnail:'/images/logo.jpg'" @error="model.exercise_image_thumbnail='/images/logo.jpg'"></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content class="pl-2 text-left">
                          <v-list-item-title
                            class="align-center"
                            v-text="model.exercise_name"
                          ></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action class="align-center ma-0">
                          <v-btn
                            icon
                            color="red"
                            dark
                          >
                            <v-icon class="handle" color="avidocolor">mdi-drag</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </template>

                  <template  v-if = "exerciseListmodel.length>1">
                    <template v-for="(supermodel,superindex) in exerciseListmodel">
                        <v-list-item  :key="superindex+supermodel.user_workout_exercise_id+supermodel.exercise_id">
                            <v-btn
                              icon
                              color="red"
                              dark
                              @click.stop.prevent="
                                removeExercise(eindex,superindex)
                              "
                            >
                              <v-icon color="red">mdi-minus-circle</v-icon>
                            </v-btn>

                          <!-- <div class="pl-9" v-if="superindex>0" ></div> -->
                          <v-list-item-avatar tile size=50>
                             <v-img class="rounded" alt="avtar_image" :src="(supermodel.exercise_image_thumbnail!=null)?supermodel.exercise_image_thumbnail:'/images/logo.jpg'" @error="supermodel.exercise_image_thumbnail='/images/logo.jpg'"></v-img>
                          </v-list-item-avatar>

                          <v-list-item-content class="pl-2 text-left">
                            <v-list-item-title class="align-center"
                              >{{supermodel.exercise_name}}</v-list-item-title
                            >
                          </v-list-item-content>

                          <v-list-item-action class="align-center ma-0"  v-if="superindex==0" >
                            <v-btn
                              icon
                              dark
                            >
                            <v-icon class="handle"  color="avidocolor">mdi-drag</v-icon>
                          </v-btn>
                          </v-list-item-action>

                        </v-list-item>

                        <v-list-item  v-if="superindex < exerciseListmodel.length - 1" :key="'icon'+superindex+supermodel.user_workout_exercise_id+supermodel.exercise_id" style="min-height: 0px !important;">
                          <v-list-item-avatar height="0px"></v-list-item-avatar>
                          <v-list-item-content class="d-block ma-n5 pa-0 text-left">
                            <v-list-item-avatar class="ml-12 mt-0 mb-0 align-center">
                              <v-img @click="removeSuperSet(eindex,superindex)"  alt="superst" :src="imageSource"></v-img>
                            </v-list-item-avatar>
                          </v-list-item-content>
                        </v-list-item>

                    </template>
                  </template>
                 </v-card>
                  <v-list-item  v-if="eindex < exerciseList.length - 1" style="min-height: 0px !important;">
                    <v-list-item-avatar height="0px"></v-list-item-avatar>
                    <v-list-item-content class="d-block ma-n5 py-4 px-0 text-left">
                      <v-list-item-avatar class="ml-12 mt-0 mb-0 align-center">
                        <v-img class="pa-2" @click="addSuperSet(eindex)"  alt="superst" :src="grayimageSource"></v-img>
                      </v-list-item-avatar>
                    </v-list-item-content>
                  </v-list-item>
                </div>
            </transition-group>
        </draggable>

        <div class="text-left text-body-1 pt-2">Finisher</div> 
        <v-card class="rounded-lg mb-1">
          <v-list-item v-if="finisher!=null">
            <v-btn
              icon
              color="red"
              dark
              @click.stop.prevent="
                removeWFC('FINISHER')
              "
            >
              <v-icon color="red">mdi-minus-circle</v-icon>
            </v-btn>
            <v-list-item-avatar tile size=50>
              <v-img class="rounded" :alt="finisher.name" :src="(finisher.image!=null)?finisher.image:'/images/logo.jpg'" @error="finisher.image='/images/logo.jpg'"></v-img>
            </v-list-item-avatar>
            <v-list-item-content class="pl-2 text-left">
              <v-list-item-title v-if="finisher!=null" >{{finisher.name}}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="align-center ma-0">
              <v-list-item-avatar class="ma-0" @click.stop.prevent="getLibraryModel(['FINISHER','ADDFINISHER',finisher.id])" >
                <v-icon medium class="avidocolor--text">mdi-shuffle-variant</v-icon>
              </v-list-item-avatar>
            </v-list-item-action>
          </v-list-item>   

          <v-list-item v-if="finisher==null"  @click.stop.prevent="(current_workout_name!=null) ? getLibraryModel(['FINISHER','ADDFINISHER',0]) : null">
              <v-list-item-avatar>
                <v-icon class="avidocolor--text">mdi-plus-box</v-icon> 
              </v-list-item-avatar>
              <v-list-item-content class="text-left">
                <v-list-item-title>Add Finisher</v-list-item-title>
              </v-list-item-content>
          </v-list-item>   
        </v-card>  

      <div class="text-left text-body-1 pt-2">Cooldown</div> 
      <v-card class="rounded-lg mb-1">
          <v-list-item v-if="cooldown!=null">
            <v-btn
              icon
              color="red"
              dark
              @click.stop.prevent="
                removeWFC('COOLDOWN')
              "
            >
              <v-icon color="red">mdi-minus-circle</v-icon>
            </v-btn>
            <v-list-item-avatar tile size=50>
              <v-img class="rounded" :alt="cooldown.name" :src="(cooldown.image!=null)?cooldown.image:'/images/logo.jpg'" @error="cooldown.image='/images/logo.jpg'"></v-img>
            </v-list-item-avatar>
            <v-list-item-content class="pl-2 text-left">
              <v-list-item-title v-if="cooldown!=null" >{{cooldown.name}}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="align-center ma-0">
              <v-list-item-avatar class="ma-0"  @click.stop.prevent="getLibraryModel(['COOLDOWN','ADDCOOLDOWN',cooldown.id])">
                <v-icon medium  class="avidocolor--text">mdi-shuffle-variant</v-icon>
              </v-list-item-avatar>
            </v-list-item-action>
          </v-list-item>   

          <v-list-item v-if="cooldown==null"  @click.stop.prevent="(current_workout_name!=null) ? getLibraryModel(['COOLDOWN','ADDCOOLDOWN',0]) : null">
              <v-list-item-avatar>
                <v-icon class="avidocolor--text">mdi-plus-box</v-icon> 
              </v-list-item-avatar>
              <v-list-item-content  class="text-left">
                <v-list-item-title>Add Cooldown</v-list-item-title>
              </v-list-item-content>
          </v-list-item>   
        </v-card>  

        <div class="clear"></div>
        <v-footer color="black" fixed padless>
          <v-row justify="center" no-gutters>
              <v-btn   @click="getFavoriteExercise('EXERICSE')" class="my-2 avidocolor--text" text>
                <v-icon>mdi-plus-box</v-icon>Exercise
              </v-btn>

             <v-btn @click="editWExercise" class="my-2 avidocolor--text" text>
                <v-icon>mdi-content-save</v-icon>save
              </v-btn> 
          </v-row>
        </v-footer>

  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import draggable from 'vuedraggable';

export default {
  name: "EditWorkoutExercise",
  data () {
    return {
      is_completed:this.$route.query.cw,
      end_workout:this.$route.query.fw,
      imageSource: "/images/superset_icon.png",
      grayimageSource: "/images/gray_superset_icon.png"
    }
  },
  components: {
    draggable
  },
  computed:{
    ...mapFields('workoutCalendar',{
      calendar_type: "calendar_type",
      current_workout_name: "current_workout_name",
      cworkout_image: "cworkout_image",
      current_workout_image: "current_workout_image",
      current_workout_description: "current_workout_description",
      current_user_workout_id: "current_user_workout_id",
    }),
    ...mapFields('workoutExercise',[
      'total_reps', 
      'total_sets', 
      'total_weight',       
      'completed_reps', 
      'completed_sets', 
      'completed_weight', 
      'exerciseList',
      'warmup', 
      'cooldown', 
      'finisher', 
      'data_change',
      'workoutExer_edit_mode',
    ]),
    
    ...mapFields('setAllExerciseSet',[
      'setall_workout_type', 
    ]),

  },
  methods: {
    ...mapActions("bottomSearch", {
      getFavoriteExercise: "getFavoriteExercise",
    }),

    ...mapActions("library", {
      getLibraryModel: "getLibraryModel",
    }),

    removeExercise(eindex,supindex) { 
      this.data_change=true
      let newexerciseList=[]
      if(this.exerciseList!=null){
        for (const [key, nexerciseList]  of Object.entries(this.exerciseList)) {
          if(nexerciseList.length>1){
            if(key==eindex){
              let firsthalfexercise=[]
              for (const [superkey, superexeriseList]  of Object.entries(this.exerciseList[eindex])) {
                if(superkey!=supindex){
                  firsthalfexercise.push(superexeriseList);
                }
              }
              newexerciseList.push(firsthalfexercise);
            }else{
              newexerciseList.push(nexerciseList);
            } 
          }else if(key!=eindex){
            newexerciseList.push(nexerciseList);
          }
        }
        this.exerciseList=newexerciseList
      }
    },  

    removeSuperSet(eindex,superindex) { 
      this.data_change=true
      let newexerciseList=[]
      if(this.exerciseList!=null){
        for (const [key, nexerciseList]  of Object.entries(this.exerciseList)) {
          if(key==eindex){
            let firsthalfexercise=[]
            let secondhalfexercise=[]
            for (const [superkey, superexeriseList]  of Object.entries(this.exerciseList[eindex])) {
              if(superkey<=superindex){
                firsthalfexercise.push(superexeriseList);
              }else{
                secondhalfexercise.push(superexeriseList);
              }
            }
            newexerciseList.push(firsthalfexercise);
            newexerciseList.push(secondhalfexercise);
          }else{
            newexerciseList.push(nexerciseList);
          }
        }
        this.exerciseList=newexerciseList
      }
    }, 
    
    addSuperSet(eindex) {
      this.data_change=true 
      if(this.exerciseList[eindex]!=null){
        let superexercise=[];
        let secondindex=eindex+1;
        for (let firstsuperexercises  of Object.values(this.exerciseList[eindex])) {           
          superexercise.push(firstsuperexercises);
        }   
        for (let secondsuperexercises  of Object.values(this.exerciseList[secondindex])) {           
          superexercise.push(secondsuperexercises);
        }
        if (eindex >= 0) this.exerciseList.splice(eindex, 1);
        this.exerciseList[eindex]=superexercise;        
      }
    },
    removeWFC(wfc_type){
      if(wfc_type=='WARMUP'){
        this.warmup=null
      }else if(wfc_type=='FINISHER'){
        this.finisher=null
      }else if(wfc_type=='COOLDOWN'){
        this.cooldown=null
      }
      this.data_change=true
    },
    setallset(){
      this.setall_workout_type='CALENDAR'
      this.$router.push({ path: 'setallexerciseset' });
    },
    editWExercise(){
      if(this.workoutExer_edit_mode){
        this.workoutExer_edit_mode=false
        this.$store.dispatch("workoutExerciseSet/saveUserWorkout", {savedata: 0}, { root: true });
        this.$router.go(-1)
      }
    },

    openUpload () {
      document.getElementById('image-upload').click()
    },
   
    onFileChange(payload) {
      //const file = payload.target.files[0]; // use it in case of normal HTML input
      const file = payload; // in case vuetify file input
      if (file) {
        this.current_workout_image = URL.createObjectURL(file);
        URL.revokeObjectURL(file); // free memory
        // console.log(payload)
      } else {
        this.current_workout_image =  null
      }
      this.data_change=true
    },
  },
  beforeDestroy(){
    // this.workoutExer_edit_mode=false
    // console.log('workoutExer_edit_mode'+this.workoutExer_edit_mode)
   /* if(this.workoutExer_edit_mode==false){
      this.$store.dispatch("workoutExerciseSet/saveUserWorkout", {savedata: 0}, { root: true });
    } */
  },

};
</script>

<style scoped>
.handle {
  cursor: move; 
}
</style>