import axios from "axios";
import { getField, updateField } from 'vuex-map-fields';
import router from '../../router'

export default {
  namespaced: true,
  state: {
    allNotes: [],
    call_all_note: true,
    currentNoteExercise: [],
    currentNote: [],
    updateNote: [],
    searchTeamList: [],
    searchTeamMemberList: [],
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    unsetAllNotes(state) {
      state.allNotes = []
      state.currentNoteExercise = []
      state.currentNote = []
      state.updateNote = []
    },
    setAllNotes(state, response_data) {
      state.allNotes = response_data
    },    
    unsetSearchTeamTeamMember(state) {
      state.searchTeamList = []
      state.searchTeamMemberList = []
    },
    setSearchTeamTeamMember(state, response_data) {
      state.searchTeamList = response_data.teams
      state.searchTeamMemberList = response_data.team_members
    },
   /*  setCurrentNotes(state, response_data) {
      state.currentNote = {
          note_id : response_data.id,
          exercise_id : response_data.exercise_id,
          notes : response_data.notes,
          is_personal_note : response_data.is_personal_note,
          assigned_userids : response_data.assigned_userids,
          assigned_teamids : response_data.assigned_teamids
        }
    },  */
  },

  actions: {
    getTeamTmember({ commit,dispatch }) {
      commit("unsetSearchTeamTeamMember");
      return new Promise((resolve,reject) => {
      axios({
        method: 'GET',
        url: 'get_coach_team_tmember',
      })
        .then(resp => {
          const resp_code = resp.status
          if (resp_code == 200) {
            const response_data = resp.data.response
            commit("setSearchTeamTeamMember",response_data);
            // router.push({ path: '/tsearch' });
            resolve(resp)
          }
        })
        .catch(error => {
          const resp_code = error.response.status
          if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
            const error_mesg = error.response.data.errors
            dispatch("setNotification", {
              display: true,
              text: error_mesg,
              alertClass: "error"
            }, { root: true });
            reject(error)
          }
        })
    })

    }, 
   
    getAllNotes({ commit, dispatch }) {
      commit("unsetAllNotes")
      return new Promise((resolve,reject) => {
        let api_name='get_allexercise_notes' 
        axios({
          method: 'GET',
          url: api_name,
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              this.state.exerciseNotes.call_all_note=false
              commit("setAllNotes", response_data);
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },

    addExerciseToNotes(context,search_exercise_ids){

      let isPersonal_note=true
      let queryParam='?pn=1'

      if(this.state.header_upgrade_to_coach){
        isPersonal_note=false
        queryParam=''
      }

      if(search_exercise_ids!=null){
          let exerInfo=search_exercise_ids
          let noteDetails = {
            id : 0,
            exercise_id : exerInfo.exercise_id,
            notes : null,
            is_personal_note : isPersonal_note,
            assigned_userids : [],
            assigned_teamids : []
          }

          /* let newModel={
            exercise_id : exerInfo.exercise_id,
            exercise_name : exerInfo.exercise_name,
            exercise_image_thumbnail : exerInfo.exercise_image_thumbnail,
            exercise_large_image : exerInfo.exercise_image_large,
            exercise_video_url : exerInfo.exercise_video_url,
            note_details : [noteDetails]         
          }
          this.state.exerciseNotes.currentNoteExercise=newModel */

          this.state.exerciseNotes.updateNote=noteDetails
          router.push({ path: '/edit-note'+queryParam });
      }
    },

    updateNotes({ dispatch }) {
      return new Promise((reject) => {
        
        let assignedUserids=null
        let assignedTeamids=null
        let noteModel=this.state.exerciseNotes.updateNote
        
        // console.log(noteModel)

        // For Add and Edit 
        let returntonote=2
        /* if(noteModel.note_id==0){
          returntonote=2
        } */

        if(!noteModel.is_personal_note){
          assignedUserids=Object.values(noteModel.assigned_userids)
                          .map(userInfo => userInfo.id)
                          .join(',');

          assignedTeamids=Object.values(noteModel.assigned_teamids)
                          .map(teamInfo => teamInfo.id)
                          .join(',');
        }

        axios({
          method: 'POST',
          url: 'edit_exercise_notes',
          data: {
            note_id: noteModel.id,
            exercise_id: noteModel.exercise_id,          
            notes: noteModel.notes,          
            is_personal_note: noteModel.is_personal_note,          
            assigned_userids: assignedUserids,          
            assigned_teamids: assignedTeamids,          
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {

              this.state.exerciseNotes.call_all_note=true
            /*
              const response_data = resp.data.response 
              const response_data = resp.data.response              
              commit("setCurrentNotes", response_data); */

              dispatch("setNotification", {
                display: true,
                text: resp.data.message,
                alertClass: "success"
              }, { root: true })
              
              router.go(-returntonote);
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    }, 

    deleteUserExerciseNote({ dispatch },model) {
      return new Promise((resolve,reject) => {
        axios({
          method: 'POST',
          url: 'delete_exercise_note',
          data: {
            note_id: model.delete_note_id,        
          }
        })
        .then(resp => {
          const resp_code = resp.status
          if (resp_code == 200) {
            this.state.exerciseNotes.currentNoteExercise.note_details.splice(model.remove_index, 1); 

            dispatch("setNotification", {
              display: true,
              text: resp.data.message,
              alertClass: "success"
            }, { root: true })
            
            resolve(resp)
          }
        })
        .catch(error => {
          const resp_code = error.response.status
          if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
            const error_mesg = error.response.data.errors
            dispatch("setNotification", {
              display: true,
              text: error_mesg,
              alertClass: "error"
            }, { root: true });
            reject(error)
          }
        })
      })
    }, 

  },

};