<template>
  <div>

    <v-app-bar
      color="black"
      dense
      dark
    > 

    <!-- Not Display on large and extra large screen-->
    <!-- <v-spacer class="d-lg-none d-xl-none"></v-spacer> -->
    <!-- <v-toolbar-title class="avidocolor--text">{{header_title}}</v-toolbar-title> -->
    <v-app-bar-nav-icon  v-if="((component_name=='HOME' || component_name=='ATHLETE' || component_name=='COACHES' || component_name=='MYPROFILE') && component_name!='LOGIN' && component_name!='REGISTER' && component_name!='FORGOTPASSWORD')" @click.stop="drawer = !drawer" ></v-app-bar-nav-icon>


      <template v-if="header_title!==''">
        <v-btn v-if="component_name!='LOGIN' && component_name!='REGISTER' && component_name!='FORGOTPASSWORD' &&  component_name!='WORKOUTEXERCISESET' && component_name!='WORKOUTWFC' && component_name!='MYPROFILE'" class="avidocolor--text" icon @click="$router.go(-1)">
          <v-icon>mdi-chevron-left</v-icon> 
        </v-btn>

        <v-spacer></v-spacer>
        <v-row justify="center">
          <v-col>
            <h2 class="d-none d-lg-block avidocolor--text text-no-wrap" @click="linkheader()">{{header_title}} 
              <v-icon  v-if="isTokenActive && (component_name=='WORKOUTWFC' || component_name=='WORKOUTEXERCISESET') && header_afterbw!=undefined" small style="vertical-align: top;" color="avidocolor" dark  @click="linkheader()">
                mdi-information
              </v-icon>
            </h2>
            <h4 class="d-lg-none avidocolor--text text-no-wrap" @click="linkheader()">{{header_title}}
              <v-icon  v-if="isTokenActive && (component_name=='WORKOUTWFC' || component_name=='WORKOUTEXERCISESET') && header_afterbw!=undefined" small style="vertical-align: top;" color="avidocolor" dark  @click="linkheader()">
                mdi-information
              </v-icon>
            </h4>
            <v-divider class="white"></v-divider>
          </v-col>
        </v-row>
      </template>
      <template  v-else>
        <v-spacer class="d-lg-none"></v-spacer>
        <router-link to="/">
          <v-img alt="Avido" width="120" src="/images/header_logo.png" />
        </router-link>
      </template>
      <v-spacer></v-spacer>
      <!-- Display only on large and extra large screen-->

      <div  v-if="!isTokenActive && (component_name!='FAQ' && component_name!='ATHLETESFAQ' && component_name!='COACHFAQ')" id="nav" class="d-none d-lg-flex d-xl-flex">
        <router-link 
          v-for="(nitem, index) in header_rnavigation_items"
          :key="nitem.title"
          :to="nitem.link"
          >
          {{ nitem.title }}
         <span class="pa-2 white--text"  v-if="index < header_rnavigation_items.length - 1" :key="index">|</span>
        </router-link> 
      </div> 

      <v-btn v-if="!isTokenActive && (component_name!='LOGIN' && component_name!='REGISTER' && component_name!='FORGOTPASSWORD')" icon to="/login">
        <v-icon>mdi-account</v-icon> 
      </v-btn>

      <!-- <v-btn v-if="isTokenActive && header_title=='My Workouts' && component_name=='WORKOUTSUMMARY'" icon  @click="$router.push({ path: '/user-profile' })" class="avidocolor--text">
        <v-icon>mdi-account-circle</v-icon> 
      </v-btn> -->

      <v-btn v-if="isTokenActive && component_name=='WORKOUTSUMMARY' && is_workout_editable" rounded small v-on:click="calendar_edit_mode=!calendar_edit_mode" class="avidocolor black--text font-weight-bold">
        {{ (calendar_edit_mode) ? 'Done' : 'Edit' }}
      </v-btn>

      <v-btn v-if="isTokenActive && (component_name=='WORKOUTEXERCISE' || component_name=='ENDWORKOUT') && is_workout_editable"  @click="editWExercise()"  rounded small class="avidocolor black--text font-weight-bold" >
        {{ (workoutExer_edit_mode) ? 'Done' : 'Edit' }}
      </v-btn>
      
      <v-btn  v-if="isTokenActive && component_name=='LIBRARY'" rounded small v-on:click="library_edit_mode=!library_edit_mode" class="avidocolor black--text font-weight-bold">
        {{ (library_edit_mode) ? 'Done' : 'Edit' }}
      </v-btn>

      <v-btn  v-if="isTokenActive && component_name=='SETALLEXERCISESET'" rounded small class="avidocolor black--text font-weight-bold" @click="changeSets">
        Save
      </v-btn>

      <v-btn  v-if="isTokenActive && component_name=='SETALLWFCREPS'" rounded small class="avidocolor black--text font-weight-bold" @click="changeallWFCreps">
        Save
      </v-btn>

      <!-- <v-btn v-if="isTokenActive && (component_name=='USERPROFILE' || component_name=='TEAMPROFILE')" icon to="/user-search" class="avidocolor--text">
        <v-icon>mdi-magnify</v-icon> 
      </v-btn>  -->
      <v-btn v-if="isTokenActive && (component_name=='EXERCISEINSTRUCTION')" icon  class="avidocolor--text"
        @click="favUnfavExer()">
        <v-icon>mdi-heart{{ (is_favorite_exercise) ? '' : '-outline' }}</v-icon> 
      </v-btn>

    </v-app-bar>

    <v-navigation-drawer
        v-model="drawer"
        class="height-app"
        temporary
        app 
      >
        <v-list-item  v-if="isTokenActive" to="/user-profile">
          <v-list-item-avatar  size=50 >
            <v-img :alt="header_user_name" :src="(user_profile_pic!=null)? user_profile_pic: 'images/logo.jpg'" @error="user_profile_pic='images/logo.jpg'"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title  class="avidocolor--text text-left"><h3>{{ (header_user_name==null) ? username : header_user_name }}</h3></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item  v-else to="/">
          <v-list-item-title><v-img alt="Avido" width="120" src="/images/logo_with_name.png" /></v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>
        
        <v-list dense>
          <v-list-item
            v-for="item in (isTokenActive) ? navigation_items : blogin_navigation_items"
            :key="item.title"
            :to="item.link"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <!-- <v-list-item-title class="text-left">{{ (item.link=='/myteamtrainers' && (header_upgrade_to_coach=='null' || header_upgrade_to_coach==false || header_upgrade_to_coach=='false')) ? 'My Teams & Coaches' : item.title }}</v-list-item-title> -->
              <v-list-item-title class="text-left">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        <!--  <v-list-item v-if="isTokenActive && (header_upgrade_to_coach=='null' || header_upgrade_to_coach==false || header_upgrade_to_coach=='false')" >
            <v-list-item-content>
              <v-list-item-title>
                <v-btn large block color="avidocolor avidosolidtextcolor--text" @click="upgradeToTrainer()"> Upgrade To Coach </v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>  -->

        </v-list>
     </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapState,mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";

  export default {
    computed:{
      ...mapState(['header_user_name','header_afterbw','header_upgrade_to_coach','header_title','component_name']),
      ...mapGetters('user',{
        isTokenActive:'isTokenActive',
      }),
      ...mapFields('user',{
        username:'username',
        user_profile_pic:'user_profile_pic',
      }),
      ...mapFields('organizationProfile',{
        organization_id:'organization_id',
        organization_name:'organization_name',
      }),
      ...mapFields('workoutCalendar',{
        calendar_edit_mode:'calendar_edit_mode',
        is_workout_editable:'is_workout_editable',
      }),   
      ...mapFields('workoutExercise',{
        workoutExer_edit_mode:'workoutExer_edit_mode',
      }),        
      ...mapFields('library',{
        library_edit_mode:'library_edit_mode',
      }),     
      ...mapFields('exerciseInstruction',{
        exercise_instruction_id:'exercise_instruction_id',
        is_favorite_exercise:'is_favorite_exercise',
        add_fav_exercise_id:'add_fav_exercise_id',
        remove_fav_exercise_id:'remove_fav_exercise_id',
      }),
    },
    data () {
      return {
        drawer: null,
        header_rnavigation_items: [
          { title: 'Home', link: '/', icon: 'mdi-home' },
          { title: 'Athletes', link: '/athlete' , icon: 'mdi-run'},
          { title: 'Coaches', link: '/coaches', icon: 'mdi-account-multiple' },
        ],

        blogin_navigation_items: [
          { title: 'Home', link: '/', icon: 'mdi-home' },
          { title: 'Athletes', link: '/athlete', icon: 'mdi-run' },
          { title: 'Coaches', link: '/coaches', icon: 'mdi-account-multiple' },
          { title: 'FAQ / Contact Us', link: '/faq', icon: 'mdi-help' },
          { title: 'Contact Us', link: '/contactus', icon: 'mdi-comment-text-outline' },
          { title: 'Login', link: '/login' , icon: 'mdi-login'},
        ],

        navigation_items: [
        //  { title: 'My Workouts', link: '/user-profile', icon: 'mdi-weight-lifter' },
        //  { title: 'My Results', link: '/results?my=1', icon: 'mdi-chart-bar' },
          { title: 'Training Preferences', link: '/trainingpreferences', icon: 'mdi-human' },  
          { title: 'Training Library', link: '/library', icon: 'mdi-library' },
          { title: 'Exercise Notes', link: '/exercisenotes', icon: 'mdi-pencil' },
        //  { title: 'My Teams & Athletes', link: '/myteamtrainers', icon: 'mdi-account-group' },
          { title: 'My Teams', link: '/myteamtrainers', icon: 'mdi-account-group' },
          { title: 'Join Teams', link: '/team-search', icon: 'mdi-magnify' },
          
          
          { title: 'Profile Settings', link: '/profilesettings', icon: 'mdi-account-settings' },
          { title: 'FAQ / Contact Us', link: '/faq', icon: 'mdi-help' },
          { title: 'Account Settings', link: '/accountsettings', icon: 'mdi-settings' },
          // { title: 'Contact Us', link: '/contactus', icon: 'mdi-comment-text-outline' },
          // { title: 'Logout', link: '/logout', icon: 'mdi-logout-variant' },
        ],
        login_user_id:this.$route.query.uid,
      }
    },
    methods: {

      ...mapActions("setAllExerciseSet", {
        changeSets: "changeSets",
        changeallWFCreps: "changeallWFCreps",
      }),
      upgradeToTrainer: function () {
        this.organization_id=0
        this.organization_name=''
        this.$router.push({ path: '/join-organization' });
          /* axios.post(`upgrade_to_trainer`).then((resp) => {
            this.$store.commit("SET_NOTIFICATION", {
              display: true,
              text: resp.data.message,
              alertClass: "success",
            });
            this.$refs.chnagepasswordform.reset();
          }).catch((error) => {
            this.$store.commit("SET_NOTIFICATION", {
              display: true,
              text: error.response.data.errors,
              alertClass: "error",
            });
          }); */
      },
      favUnfavExer(){
        this.remove_fav_exercise_id=0
        this.add_fav_exercise_id=0
        if(this.is_favorite_exercise){
          this.remove_fav_exercise_id=this.exercise_instruction_id
          this.is_favorite_exercise=false
        }else{
          this.add_fav_exercise_id=this.exercise_instruction_id
          this.is_favorite_exercise=true
        }
      },
      editWExercise(){

        let is_completed=(isNaN(parseFloat(this.$route.query.cw))) ? 0 : parseInt(this.$route.query.cw)
        let end_workout=(isNaN(parseFloat(this.$route.query.fw))) ? 0 : parseInt(this.$route.query.fw)
      //  console.log(is_completed)
      //  console.log(end_workout)
        if(this.workoutExer_edit_mode){
          this.workoutExer_edit_mode=false
          this.$router.go(-1)
        }else{
          this.workoutExer_edit_mode=true
          this.$router.push({ path: 'editworkoutexercise?cw='+is_completed+'&fw='+end_workout });
        }
      },
      linkheader(){
        let qbr=parseInt(this.$route.query.br)+1
        if((this.component_name=='WORKOUTEXERCISESET' || this.component_name=='WORKOUTWFC') && this.header_afterbw!=undefined){
          this.$router.push({ path: 'endworkout?fw=1&br='+qbr });
        }
      },
    }
  }
</script>