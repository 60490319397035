<template>
  <div class="teamtrainingpreferencesform">

    <v-card flat class="pa-8">
      <v-form ref="teamtrainingpreferencesform" v-model="valid" lazy-validation @submit.prevent="onSubmit"
        method="post">
        <v-select v-model="current_calendar" :items="current_calendar_option" item-color="avidocolor"
          label="Program Type" color="avidocolor" class="pa-md-4" 
          :rules="[(v) => !!v || 'Program Type is required']"></v-select>

        <v-select v-if="current_calendar == 'LIBRARY'" v-model="assign_library_program_id" :items="library_program"
          item-text="name" item-value="id" item-color="avidocolor" label="Select Library Program" color="avidocolor"
          class="pa-md-4" 
          :rules="(current_calendar == 'LIBRARY') ? [(v) => !!v || 'Library Program is required'] : []" return-object>
        </v-select>

        <template v-if="current_calendar == 'LIBRARY'">
          <v-dialog ref="dialog" v-model="assignprogram_startdate" :return-value.sync="assign_program_startdate"
            persistent width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field color="avidocolor" class="pa-md-4" v-model="assign_program_startdate"
                label="Select Program Start Date"  readonly v-bind="attrs" v-on="on"
                :error="(assign_program_startdate == null) ? true : false"></v-text-field>
            </template>
            <v-date-picker v-model="assign_program_startdate" color="avidocolor black--text" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="avidocolor" @click="assignprogram_startdate = false">
                Cancel
              </v-btn>
              <v-btn text color="avidocolor" @click="$refs.dialog.save(assign_program_startdate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </template>

        <v-select :disabled="(current_calendar == 'AVIDO') ? false : true" v-model="program_type"
          :items="program_type_option" item-color="avidocolor" label="Sport or Goal" color="avidocolor" class="pa-md-4"
          :rules="(current_calendar == 'AVIDO') ? [(v) => !!v || 'Sport or Goal is required'] : []"
          @change="onChangeProgramType()"></v-select>

        <v-select :disabled="(current_calendar == 'AVIDO' && program_type == 'SPORT') ? false : true"
          v-model="goal_sport_id" :items="sport_option" item-text="name" item-value="id" item-color="avidocolor"
          label="Select Sport" color="avidocolor" class="pa-md-4" 
          :rules="(current_calendar == 'AVIDO' && program_type == 'SPORT') ? [(v) => !!v || 'Sport is required'] : []">
        </v-select>

        <v-select :disabled="(current_calendar == 'AVIDO' && program_type == 'GOAL') ? false : true" v-model="goal_sport_id"
          :items="goal_option" item-text="name" item-value="id" item-color="avidocolor" label="Select Goal"
          color="avidocolor" class="pa-md-4"
          :rules="(current_calendar == 'AVIDO' && program_type == 'GOAL') ? [(v) => !!v || 'Goal is required'] : []">
        </v-select>

        <v-select :disabled="(current_calendar == 'AVIDO') ? false : true" v-model="no_workouts_per_week"
          :items="no_workouts_per_week_option" item-color="avidocolor" label="Workout Per Week" color="avidocolor"
          class="pa-md-4 pb-md-0" 
          :rules="(current_calendar == 'AVIDO') ? [(v) => !!v || 'Workout Per Week  is required'] : []"></v-select>

        <v-row class="pl-md-4">
          <v-col cols="7" class="pb-0 text-left">
            <v-label>Available Equipment</v-label>
          </v-col>
          <v-col cols="5">
            <v-checkbox
              v-model="tselectAll"
              :label="'Select All'"
              :disabled="(current_calendar=='AVIDO') ? false : true"
              @change="ttoggleSelectAll"
              color="avidocolor"
              hide-details
              class="mt-0 pr-md-4 float-right"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row class="pl-md-12">
          <v-col cols="6" sm="2" md="2" v-for="equp_item in equipment_option" :key="equp_item.id">
            <v-checkbox :disabled="(current_calendar!='AVIDO') ? true : (equp_item.id==15 ? true : false)"
              v-model="team_equipments" :label="equp_item.name" :value="equp_item.id" :checked="true" color="avidocolor"
              hide-details class="mt-0"></v-checkbox>
          </v-col>
        </v-row>

        <v-dialog v-if="current_calendar == 'AVIDO'" ref="programstart_dialog" v-model="programstart_modal"
          :return-value.sync="program_start_date" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field  :value="formateDates('PS')" label="Program Start Date" readonly
              v-bind="attrs" v-on="on" color="avidocolor" class="pa-md-4 pt-md-8"></v-text-field>
          </template>
          <v-date-picker v-model="program_start_date" scrollable color="avidocolor black--text">
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="programstart_modal = false">
              Cancel
            </v-btn>
            <v-btn text color="avidocolor" @click="
              $refs.programstart_dialog.save(
                program_start_date
              )
            ">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <template v-if="current_calendar == 'AVIDO' && program_type == 'SPORT'">
          <v-dialog ref="sport_inseason_start_dialog" v-model="sport_inseason_start_modal"
            :return-value.sync="sport_inseason_start" persistent width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :value="formateDates('SPINS')" label="Inseason Start Date" 
                clearable @click:clear="sport_inseason_start = null" readonly v-bind="attrs" v-on="on" color="avidocolor" class="pa-md-4"></v-text-field>
            </template>
            <v-date-picker v-model="sport_inseason_start" scrollable color="avidocolor black--text">
              <v-spacer></v-spacer>
              <v-btn text color="error" @click="sport_inseason_start_modal = false">
                Cancel
              </v-btn>
              <v-btn text color="avidocolor" @click="
                $refs.sport_inseason_start_dialog.save(
                  sport_inseason_start
                )
              ">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <v-dialog ref="sport_inseason_end_dialog" v-model="sport_inseason_end_modal"
            :return-value.sync="sport_inseason_end" persistent width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :value="formateDates('SPINE')" label="Inseason End Date" readonly
                clearable @click:clear="sport_inseason_end = null" v-bind="attrs" v-on="on" color="avidocolor" class="pa-md-4"></v-text-field>
            </template>
            <v-date-picker v-model="sport_inseason_end" scrollable color="avidocolor black--text" :min="today">
              <v-spacer></v-spacer>
              <v-btn text color="error" @click="sport_inseason_end_modal = false">
                Cancel
              </v-btn>
              <v-btn text color="avidocolor" @click="
                $refs.sport_inseason_end_dialog.save(
                  sport_inseason_end
                )
              ">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </template>

        <v-dialog v-if="current_calendar == 'AVIDO' && program_type == 'GOAL'" ref="goal_end_date_dialog"
          v-model="goal_end_date_modal" :return-value.sync="goal_end_date" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="formateDates('GE')" label="Goal End Date"  readonly
              v-bind="attrs" v-on="on" color="avidocolor" class="pa-md-4"></v-text-field>
          </template>
          <v-date-picker v-model="goal_end_date" scrollable color="avidocolor black--text" :min="today">
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="goal_end_date_modal = false">
              Cancel
            </v-btn>
            <v-btn text color="avidocolor" @click="
              $refs.goal_end_date_dialog.save(goal_end_date)
            ">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <v-footer color="black" fixed padless>
          <v-row justify="center" no-gutters>
            <v-btn type="submit"  class="my-2 avidocolor--text" text>
              <v-icon>mdi-content-save</v-icon>Save
            </v-btn>
          </v-row>
        </v-footer>

      </v-form>
    </v-card>
    <div class="clear"></div>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import { format, parseISO } from 'date-fns'

export default {
  name: "TeamTrainingPreferences",
  data () {
    return {
      today: new Date().toISOString().substr(0, 10),
      valid: false,
      assignprogram_startdate:  false,
      programstart_modal: false,
      sport_inseason_start_modal: false,
      sport_inseason_end_modal: false,
      goal_end_date_modal: false,
      tselectAll: false,
      routecount:this.$route.query.rc
    }
  },
  computed: {
    ...mapFields('teamTrainingPreferences', {
      current_calendar: 'team_calendar_type',
      program_type: 'program_type',
      program_start_date: 'program_start_date',
      sport_inseason_start: 'sport_inseason_startdate',
      sport_inseason_end: 'sport_inseason_enddate',
      goal_end_date: 'goal_end_date',
      no_workouts_per_week: 'no_of_workout_per_week',
      goal_sport_id: 'goal_sport_id',
      team_equipments: 'team_equipments',
      current_calendar_option: 'current_calendar_option',
      no_workouts_per_week_option: 'no_workouts_per_week_option',
      goal_option: 'goal_option',
      sport_option: 'sport_option',
      muscles_option: 'muscles_option',
      equipment_option: 'equipment_option',
      library_program: 'library_program',
      program_type_option: 'program_type_option',
    }),
    ...mapFields("workoutCalendar", {
      assign_library_program_id: "assign_library_program_id",
      assign_program_startdate: "assign_program_startdate",
    }),
  },
  methods: {
    ...mapActions('teamTrainingPreferences', {
      getTrainingModel: 'getTrainingModel',
      updateTrainingModel: 'updateTrainingModel',
    }),
    ...mapActions("workoutCalendar", {
      addLibraryProgramToCalendar: "addLibraryProgramToCalendar",
    }),
    formateDates(datetype) {
      if(datetype=='PS'){
        return this.program_start_date ? format(parseISO(this.program_start_date), 'MM-dd-yyyy') : ''
      }else if(datetype=='SPINS'){
        return  this.sport_inseason_start ? format(parseISO(this.sport_inseason_start), 'MM-dd-yyyy') : ''
      }else if(datetype=='SPINE'){
        return this.sport_inseason_end ? format(parseISO(this.sport_inseason_end), 'MM-dd-yyyy') : ''
      }else if(datetype=='GE'){
        return this.goal_end_date ? format(parseISO(this.goal_end_date), 'MM-dd-yyyy') : ''
      }
    },
    onChangeProgramType: function () {
      this.goal_sport_id = 0
    },
    ttoggleSelectAll() {
      if (this.tselectAll) {
        this.team_equipments = this.equipment_option
          .map(equp_item => equp_item.id);
      } else {
        this.team_equipments = [15];
      }
    },
    onSubmit: function () {

      if (this.$refs.teamtrainingpreferencesform.validate()) {
        if (this.current_calendar == "LIBRARY") {
          this.addLibraryProgramToCalendar();
        } else {
          this.updateTrainingModel(this.routecount);
        }
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
  },
  created() {
    this.$store.commit("workoutCalendar/resetLPROGRAMState");
    this.getTrainingModel();
    if(this.equipment_option.length==this.team_equipments.length){
      this.tselectAll=true
    }
  },
};
</script>