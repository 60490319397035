<template>
  <div class="userProfile">
    <v-row align="center" justify="center">
      <v-col>
        <v-avatar class="elevation-10" size="200">
          <v-img :alt="user_name" :src="(profile_url!=null)?profile_url:'/images/logo.jpg'" @error="profile_url='/images/logo.jpg'"></v-img>
        </v-avatar>
      </v-col>
    </v-row>

    <v-card color="black" class="rounded-lg my-2">
      <template v-if="is_private_account==true">
        <div class="pa-2 text-h5">This account is private</div>
      </template>
      <template v-else>
        <v-row>
          <!-- <v-col cols="4" class="pa-2"> <span class="text-h5">{{total_reps}} </span> <br/>Repetitions</v-col>
          <v-col cols="4" class="pa-2"> <span class="text-h5">{{total_weight}} </span> <br/>Pounds</v-col>
          <v-col cols="4" class="pa-2"> <span class="text-h5">{{total_workouts}} </span> <br/>Workouts</v-col> -->
          <v-col cols="4"> 
            <v-list-item class="pa-0">
              <v-list-item-content class="py-0">
                <v-list-item-subtitle class="text-uppercase">Repetitions</v-list-item-subtitle>
                <v-list-item-title class="text-h5">{{total_reps}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="4"> 
            <v-list-item class="pa-0">
              <v-list-item-content class="py-0">
                <v-list-item-subtitle class="text-uppercase">Pounds</v-list-item-subtitle>
                <v-list-item-title class="text-h5">{{total_weight}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="4"> 
            <v-list-item class="pa-0">
              <v-list-item-content class="py-0">
                <v-list-item-subtitle class="text-uppercase">Workouts</v-list-item-subtitle>
                <v-list-item-title class="text-h5">{{total_workouts}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <template v-if="bio!=null && bio!='null'">
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content class="text-left align-self-start">
              <v-list-item-title>
                <span  style="white-space: pre-line;">
                {{bio}}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
    </v-card>

      <template v-if="my_teams.length > 0">
        <div class="text-left text-body-1 pt-2">Teams</div> 
          <template v-for="(team, mindex) in my_teams">
            <v-card class="rounded-lg mb-1" :key="mindex">
            <v-list-item
              :key="mindex+team.id"
                @click="getTeamProfile(team)"
            >
              <v-list-item-avatar  size=50>
              <v-img :alt="mindex+team.name" :src="(team.image!=null) ? team.image : '/images/logo.jpg'" @error="team.image='/images/logo.jpg'"></v-img>
              </v-list-item-avatar>
              
              <v-list-item-content class="text-left">
                <v-list-item-title v-text="team.name"  class="pl-2"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>   
            </v-card>
          </template>  
      </template>

      <template v-if="my_teams_members.length > 0">
        <div class="text-left text-body-1 pt-2">Team Member</div> 
          <template v-for="(teammember, mbindex) in my_teams_members">
            <v-card class="rounded-lg mb-1" :key="'mb_'+mbindex">
            <v-list-item
                @click="getUserProfile(teammember.id,teammember.name)"
              >
              <v-list-item-avatar  size=50>
              <v-img :alt="mbindex+teammember.name" :src="(teammember.profile_pic!=null) ? teammember.profile_pic : '/images/logo.jpg'" @error="teammember.profile_pic='/images/logo.jpg'"></v-img>
              </v-list-item-avatar>
              
              <v-list-item-content class="text-left">
                <v-list-item-title v-text="teammember.name"  class="pl-2"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>   
            </v-card>
          </template>  
      </template>

      <v-dialog v-model="change_training_dialog" max-width="300">
        <v-card>
          <v-card-title>
            Switch Training to Team Workouts?
          </v-card-title>
          
          <v-card-text>
            Would you like to switch your current training program to this teams workouts right now?
            (this can always be changed in your menu under training preferences)
          </v-card-text>
          <v-divider></v-divider>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="change_training_dialog = false">
              No
            </v-btn>

            <v-btn color="avidocolor" text @click.stop.prevent="swichtrainingCalendertoteam">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    
      <div class="clear"></div>
      <v-footer color="black" fixed padless>
        <v-row justify="center" no-gutters>
          <v-btn @click="getworkoutcalendar()"  class="my-2 avidocolor--text" text >
            <v-icon>mdi-dumbbell</v-icon>Workouts
          </v-btn>
          <v-btn  @click="getUserResult()"  class="my-2 avidocolor--text" text >
            <v-icon>mdi-chart-bar</v-icon>Results
          </v-btn>
          <!-- <v-btn v-if="login_user_id!=user_id && is_my_trainer==false && upgrade_to_trainer==true" @click="joinTrainer()"  class="my-2 avidocolor--text" text >
            <v-icon>mdi-account-plus</v-icon>Join Trainer
          </v-btn> -->
        </v-row>
      </v-footer>

  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "UserProfile",
  data () {
    return {
      get_user_profile_id:this.$route.query.uid,
      get_user_profile_name:this.$route.query.uname,
      login_user_id:localStorage.getItem('user_id'),
    }
  },
  computed:{
    ...mapFields('userProfile',{
      user_id:'user_id',
      first_name:'first_name',
      user_name:'user_name',
      profile_url:'profile_url',
      bio:'bio',
      total_reps:'total_reps',
      total_weight:'total_weight',
      total_workouts:'total_workouts',
      my_teams:'my_teams',
      my_teams_members:'my_teams_members',
      upgrade_to_trainer:'upgrade_to_trainer',
    //  is_my_trainer:'is_my_trainer',
      trainer_user_id:'trainer_user_id',
      is_private_account:'is_private_account',
    }),
    ...mapFields('results',{
      result_user_id:'user_id',
      result_team_id:'team_id'
    }),
    ...mapFields('teamProfile',{
      team_id:'team_id',
      team_name:'team_name',
      change_training_dialog:'change_training_dialog',
    }),  
    ...mapFields('workoutCalendar',{
      calendar_date:'calendar_date'
    }),
  },
  methods: {
    ...mapActions('userProfile',{
      getuserProfileModel:'getuserProfileModel'      
    }),
    ...mapActions('teamProfile',{
      getTeamProfileModel:'getTeamProfileModel',
      swichtrainingCalendertoteam:'swichtrainingCalendertoteam',
    }),
    getTeamProfile(team) {
      this.team_id = team.id
      this.team_name=team.name
      this.$router.push({ path: 'team-profile' });
    },  
    getworkoutcalendar(){
      this.calendar_date=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      if(this.login_user_id==this.user_id){
        this.$router.push({ path: 'week-summary' });
      }else{
        this.$router.push({ path: 'week-summary?uid='+this.user_id+'&tid=0' });
      }
    },
    getUserResult(){
      this.result_user_id = this.user_id;
      this.result_team_id = 0;
      this.$router.push({ path: 'results' });
    },
    getUserProfile(userid,username) {
      this.$router.push({ path: 'user-profile?uid='+userid+'&uname='+username });
    },  
    /* joinTrainer(){
      this.trainer_user_id = this.user_id;
      this.$router.push({ path: 'join-trainer' });
    } */
  }, 
  watch: {
    '$route.query.uid'(uid) {
      let uname=(uid==undefined) ? localStorage.getItem('user_name') : this.$route.query.uname
      this.user_id=(uid==undefined) ? 0 : uid
      this.$store.commit('changeHeaderTitle',uname);
      this.getuserProfileModel();
    },
    '$route.query.my'(my_profile) {
        if(my_profile!=undefined && my_profile==1){
          this.user_id = 0
          this.user_name=localStorage.getItem('user_name')
        }
        this.$store.commit('changeHeaderTitle',this.user_name);
        this.getuserProfileModel();
    },
  },
  created() {
    this.profile_url=null
    if(this.get_user_profile_id!=undefined){
      this.user_id=this.get_user_profile_id
      this.user_name=this.get_user_profile_name
    }
    if(this.get_user_profile_id==undefined){
      this.user_id=0
      this.user_name=localStorage.getItem('user_name')
    }    
    this.$store.commit('changeHeaderTitle',this.user_name);
   // console.log('Hi')
    this.getuserProfileModel();
  },
};
</script>
